/**
 * Created by ds on 5/25/16.
 */
var App = require('../../app');

App
    .config(
        ['$stateProvider',
            function ($stateProvider) {
                $stateProvider
	                .state('alka', {
	                    url: '/alka',
	                    templateUrl: 'templates/page_signin.html',
	                    controller: 'AlkaLoginController',
	                    ncyBreadcrumb:{
	                        label:'Alka Login'
	                    }
	                })
	                .state('alka-createpassword', {
	                    url: '/alka/createpassword',
	                    templateUrl: 'templates/app.alka.create.password.html',
	                    controller: 'AlkaCreatePasswordController',
	                    ncyBreadcrumb:{
	                        label:'Alka Create Password'
	                    }
	                })
            }
        ]);