var App = require('../../app');

App
    .config(
        ['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('app.tool-alka-movechaindatatotheotheragent', {
                        url: '/tool/alka/movechaindatatootheragent',
                        templateUrl: 'templates/app.tool.movechaindatatootheragent.html',
                        controller: 'ToolMoveChainDataToOtherAgentController',
                        ncyBreadcrumb:{
                            label:'Move Data'
                        }
                    })
            }
        ]);