/**
 * Created by nover on 16/12/14.
 */
'use strict';
var angular = require('angular');
angular.module('app').factory('DashboardService', function ($http, $log) {
    var DashboardService = {};

    DashboardService.getHome = function ( filters) {
        var filtersString = '?';
        filtersString += 'limit='+filters.limit+'&survey='+filters.survey;
        if (filters.hideJobsWithZeroImport == true) {
            filtersString += '&hideJobsWithZeroImport=true';
        }
        if (filters.showLastJobOnly == true) {
            filtersString += '&showLastJobOnly=true';
        }
        if (filters.dateFrom) {
            filtersString += '&dateFrom='+filters.dateFrom;
            if (filters.dateTo) {
                filtersString += '&dateTo='+filters.dateTo;
            }
        }
        return $http.get('/api/dashboard/home'+filtersString);
    };

    DashboardService.getJobMetrics = function () {
        return $http.get('/api/dashboard/jobmetrics');
    };

    DashboardService.getJobMetricsOverTime = function () {
        return $http.get('/api/dashboard/jobmetrics/overtime');
    };

    DashboardService.getChainMetrics = function () {
        return $http.get('/api/dashboard/chainmetrics/');
    };

    DashboardService.getFlowMetrics = function (dateFrom) {
        return $http.get('/api/dashboard/flowmetrics/' + dateFrom);
    };

    DashboardService.getFlowMetricsV2 = function (dateFrom, filterByField) {
        return $http.get('/api/v2/dashboard/flowmetrics/' + dateFrom + '?filterByField=' + filterByField);
    };

    DashboardService.getCompanyMetrics = function (id) {
        // $log.debug('get company metrics...');
        return $http({
            method: 'GET',
            url: '/api/dashboard/chainmetrics/company/' + id
        });
    };

    DashboardService.getSurveys = function () {
        // $log.debug('get surveys...');
        return $http({
            method: 'GET',
            url: '/api/dashboard/surveys/'
        });
    };

    DashboardService.getLogImport = function (page, filters) {
        var queryString = '';
        if (filters) {
            if (filters.survey) {
                queryString = 'survey=' + filters.survey;
            }

            if (filters.dateFrom && filters.dateTo) {
                if (queryString != '') {
                    queryString += '&dateFrom=' + filters.dateFrom + '&dateTo=' + filters.dateTo;
                } else {
                    queryString += 'dateFrom=' + filters.dateFrom + '&dateTo=' + filters.dateTo;
                }
            }

            if (filters.hideEmptyMessage == 'true') {
                if (queryString != '') {
                    queryString += '&hideEmptyMessage=' + filters.hideEmptyMessage;
                } else {
                    queryString += 'hideEmptyMessage=' + filters.hideEmptyMessage;
                }
            }

            if (queryString != '') {
                queryString = '?' + queryString;
            }
        }

        return $http({
            method: 'GET',
            url: '/api/dashboard/log/import/' + page + queryString
        });
    }

    DashboardService.getLogExport = function (page, filters) {
        var queryString = '';
        if (filters) {
            if (filters.survey) {
                queryString = 'survey=' + filters.survey;
            }

            if (filters.dateFrom && filters.dateTo) {
                if (queryString != '') {
                    queryString += '&dateFrom=' + filters.dateFrom + '&dateTo=' + filters.dateTo;
                } else {
                    queryString += 'dateFrom=' + filters.dateFrom + '&dateTo=' + filters.dateTo;
                }
            }

            if (filters.hideEmptyMessage == 'true') {
                if (queryString != '') {
                    queryString += '&hideEmptyMessage=' + filters.hideEmptyMessage;
                } else {
                    queryString += 'hideEmptyMessage=' + filters.hideEmptyMessage;
                }
            }

            if (queryString != '') {
                queryString = '?' + queryString;
            }
        }

        return $http({
            method: 'GET',
            url: '/api/dashboard/log/export/' + page + queryString
        });
    }

    DashboardService.getLogDataCollection = function (page) {
        // $log.debug('get log datacollection...');
        return $http({
            method: 'GET',
            url: '/api/dashboard/log/datacollection/' + page
        });
    }
    DashboardService.getLogReporting = function (page) {
        // $log.debug('get log reporting...');
        return $http({
            method: 'GET',
            url: '/api/dashboard/log/reporting/' + page
        });
    }
    DashboardService.getLogIndexesGeneration = function () {
        $log.debug('getLogIndexesGeneration.....');
        return $http({
            method: 'GET',
            url: '/api/dashboard/log/indexesgeneration/1'
        });
    }


    //DashboardService.getTest = function() {
    //    return $http.get('/api/dashboard/test');
    //}
    return DashboardService;
});