/**
 * Created by nover on 15/01/15
 */
var App = require('../../app');

App.controller('CompanyUserEditController', function($scope, $log, $modal, $window, toaster, CompanyUserService, authUser, loggedInAuthUser){
	
    $scope.authUser = authUser.data.user;
    $scope.companies = authUser.data.companies;
    $scope.companies.push($scope.authUser.company);

	$scope.editDisable = true;
	$scope.loggedInAuthUser = loggedInAuthUser.data;
	if($scope.loggedInAuthUser.role == 'admin') {
		$scope.editDisable = false;

	}
		
    $scope.save = function(authUser){

        authUser.companyId = authUser.company.id;

        CompanyUserService.updateCompanyUser(authUser)
            .then(function(data){
                toaster.pop('success', 'Changes saved', 'Your changes have been successfully saved');
            })
            .catch(function(error){
                $log.error(error);
                toaster.pop('error', 'Error saving data', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };
    
    $scope.saveTeamSwitch = function(authUser) {
        CompanyUserService.switchUserCompany(authUser)
        .then(function(response){
            toaster.pop('success', 'Change Team', response.data.msg);
        })
        .catch(function(error){
            $log.error(error);
            toaster.pop('error', 'Error saving data', error.data.msg + ' (HTTP: ' + error.status + ')');
        });
    };
    
    $scope.goBack = function () {
        $window.history.back();
    };
});