'use strict';
var angular = require('angular');
var App = require('./app');
var Config = require('./config');
var Lazyload = require('./config.lazyload');
var Routing = require('./config.router');
var $ = require('jquery');
var jQuery = require('jquery');
/* Controllers */
App
    .controller('AppCtrl', ['$scope', '$translate', '$localStorage', '$window', '$location', '$state', '$log', 'toaster',
        function ($scope, $translate, $localStorage, $window, $location, $state, $log, toaster) {
            // add 'ie' classes to html
            var isIE = !!navigator.userAgent.match(/MSIE/i);
            isIE && angular.element($window.document.body).addClass('ie');
            isSmartDevice($window) && angular.element($window.document.body).addClass('smart');

            // config
            $scope.app = {
                name: 'RMPA',
                version: 'RMPA v0.1',
                logo: 'img/rm/logo.svg',
                // for chart colors
                color: {
                    primary: '#7266ba',
                    info: '#23b7e5',
                    success: '#27c24c',
                    warning: '#fad733',
                    danger: '#f05050',
                    light: '#e8eff0',
                    dark: '#3a3f51',
                    black: '#1c2b36'
                },
                settings: {
                    themeID: 1,
                    navbarHeaderColor: 'bg-black',
                    navbarCollapseColor: 'bg-white-only',
                    asideColor: 'bg-black',
                    buttonColor: 'btn-primary',
                    backgroundColor: 'bg-light',
                    headerFixed: true,
                    asideFixed: false,
                    asideFolded: false,
                    asideDock: false,
                    container: false
                }
            };

            $scope.now = new Date();

            // save settings to local storage
            if (angular.isDefined($localStorage.settings)) {
                $scope.app.settings = $localStorage.settings;
            } else {
                $localStorage.settings = $scope.app.settings;
            }
            $scope.$watch('app.settings', function () {
                if ($scope.app.settings.asideDock && $scope.app.settings.asideFixed) {
                    // aside dock and fixed must set the header fixed.
                    $scope.app.settings.headerFixed = true;
                }
                // save to local storage
                $localStorage.settings = $scope.app.settings;
            }, true);

            // angular translate
            $scope.lang = {isopen: false};
            $scope.langs = {en: 'English', de_DE: 'German', it_IT: 'Italian'};
            $scope.selectLang = $scope.langs[$translate.proposedLanguage()] || "English";
            $scope.setLang = function (langKey, $event) {
                // set the current lang
                $scope.selectLang = $scope.langs[langKey];
                // You can change the language during runtime
                $translate.use(langKey);
                $scope.lang.isopen = !$scope.lang.isopen;
            };

            function isSmartDevice($window) {
                // Adapted from http://www.detectmobilebrowsers.com
                var ua = $window['navigator']['userAgent'] || $window['navigator']['vendor'] || $window['opera'];
                // Checks for iOs, Android, Blackberry, Opera Mini, and Windows mobile devices
                return (/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua);
            }

            $scope.$on('event:auth-loginRequired', function () {
                $log.debug('event:auth-loginRequired');
                $log.debug($scope.app.domain);
                if($scope.app.domain == "@alka.dk") {
                    $state.go('alka');
                }
                else {
                    $state.go('access.signin');
                }
            });
            $scope.$on('event:auth-loginConfirmed', function () {
                $log.debug('event:auth-loginConfirmed');
            });

            $scope.$on('$stateChangeError',
                function (event, toState, toParams, fromState, fromParams, error) {
                    $log.error(error);
                    toaster.pop('error', 'Unable to change view', error.data.msg + '. STATUS: ' + error.status);
                });
        }]);


// then our app components...
require('./app/index.js');