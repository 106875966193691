/**
 * Created by nover on 16/12/14.
 */
var App = require('../../app');

App.controller('NavigationController', function ($scope, $log, $state, Session) {
    $scope.userInfo = Session.user;

    var role = $scope.userInfo.role;
    $scope.role = role;
    if (role === 'member') {
    	$scope.role = 'user';
    }
});