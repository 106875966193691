'use strict';
var app = require('../../app');
/* Controllers */
// signin controller
app.controller('ForgotPwdFormController', ['$scope', '$http', '$state', 'authService', 'AuthExtendedService', function ($scope, $http, $state, authService, AuthExtendedService) {
    $scope.credentials = {
        email: ''
    };

    $scope.forgotPwd = function (credentials) {
        $scope.isCollapsed = !$scope.isCollapsed;
        // Try sending password
        $scope.forgotPwdAct = function (credentials) {
            AuthExtendedService.forgotPwd(credentials);
        };

        // Force actual forgot password
        $scope.forgotPwdAct(credentials);
    }
}]);
