/**
 * Created by nover on 20/12/14.
 */
var App = require('../../app');

App
    .config(
    ['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.memberadmin-dashboard-selection', {
                    url: '/memberadmindashboard/selection',
                    templateUrl: 'templates/app.memberadmindashboard.selection.html',
                    controller: 'MemberAdminDashboardSelectionController',
                    ncyBreadcrumb:{
                        label:'Responses Selection'
                    }
                })
        }
    ]);