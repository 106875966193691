'use strict';
var App = require('../../app');

App.controller('DashboardFlowV2Controller', function ($scope, $log, DashboardService, $interval, $filter, $localStorage) {


    $scope.filterByField = 'rmImportedAt';
    $scope.filterByFieldTitle = 'Import Date';
    $scope.filterByFieldData = {
        'rmImportedAt': 'Import Date',
        'transDate': 'Transaction Date'
    }

    $scope.today = function() {
        $scope.selectedDate = new Date();
    };
    $scope.today();

    var fromDate = $filter('date')($scope.selectedDate,'dd-MM-yyyy');
    var busy = true;
    $scope.loading = true;

    $scope.clear = function () {
        $scope.selectedDate = null;
    };

    // Disable weekend selection
    $scope.disabled = function(date, mode) {
        return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
    };

    $scope.toggleMin = function() {
        $scope.minDate = $scope.minDate ? null : new Date();
    };
    $scope.toggleMin();

    $scope.open = function($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened = true;
    };

    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };

    if ($localStorage.selectedDate) {
        console.log();
    }

    $scope.formats = ['dd-MM-yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    $scope.dateSelected = function () {

        if (!busy)
        {
            var apiReq = DashboardService.getFlowMetricsV2($filter('date')($scope.selectedDate,'dd-MM-yyyy'), $scope.filterByField);
            busy = true;
            $scope.loading = true;
            apiReq.then(function (value) {
                $localStorage.selectedDate = $scope.selectedDate;
                delete value.data.error;
                delete value.data.status;
                $scope.companies = value.data;
                busy = false;
                $scope.loading = false;
            });
        }
    }

    $scope.filterByFieldChange = function() {
        $scope.filterByFieldTitle = $scope.filterByFieldData[$scope.filterByField];
    }

    /*
    //Initial request
    var apiReq = DashboardService.getFlowMetricsV2(fromDate);
    apiReq.then(function (value) {
        delete value.data.error;
        delete value.data.status;
        $scope.companies = value.data;
        busy = false;
        $scope.loading = false;
        $localStorage.selectedDate = $scope.selectedDate;
    });*/

    busy = false;
    $scope.loading = false;

});