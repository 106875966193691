/**
 * Created by nover on 09/01/15.
 */
var App = require('../../app');

App.controller('CustomerUserChangePasswordModalController', function ($scope, $log, $modalInstance, $stateParams, ChainUserService, toaster) {
    // $log.debug('Change customer user password');

    $scope.ok = function (password) {
        // $log.debug(password);

        ChainUserService.updatePassword($stateParams.id, password)
            .then(function (data) {
                // $log.debug(data);
                toaster.pop('success', 'Password changed', 'The password was successfully changed');
                $modalInstance.close(data.data);

            }).catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to change password', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
