/**
 * Created by nover on 15/01/15.
 */
var App = require('../../app');
App
    .config(
    ['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.rmpa-companyUser-list', {
                    url: '/companyUser/list',
                    templateUrl: 'templates/app.companyUser.list.html',
                    controller: 'CompanyUserListController',
                    ncyBreadcrumb: {
                        label: 'User list'
                    },
                    resolve: {
                        authUser: function (UserSettingsService) {
                            return UserSettingsService.getAuthUser();
                        }
                    }
                })
                .state('app.rmpa-companyUser-edit', {
                    url: '/companyUser/edit/:id',
                    templateUrl: 'templates/app.companyUser.edit.html',
                    controller: 'CompanyUserEditController',
                    ncyBreadcrumb: {
                        label: 'User Edit'
                    },
                    resolve: {
                        authUser: function ($stateParams, CompanyUserService) {
                            return CompanyUserService.getCompanyUser($stateParams.id);
                        },
                        loggedInAuthUser: function (UserSettingsService) {
                            return UserSettingsService.getAuthUser();
                        }
                    }
                })
        }
    ]);