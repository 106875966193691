/**
 * Created by ds on 4/21/16
 */
'use strict';
var App = require('../../app');

App.controller('AlkaLoginController', function ($scope, $http, $state, authService, AuthExtendedService) {
    $scope.app.name = "Alka";
    $scope.app.domain = "@alka.dk";
    $scope.app.logo = "img/alka/logo.svg";
    $scope.app.settings.headerFixed = true;
    $scope.app.settings.buttonColor = 'btn-black';

    $scope.user = {};
    $scope.authError = null;
    $scope.credentials = {
        username: '',
        password: ''
    };

    $scope.login = function (credentials, domain) {
        var sendCredentials = {
            email: credentials.email,
            password: credentials.password,
        }

        // console.log(domain);
        if(domain !== undefined) {
            sendCredentials.email += domain;
            // console.log(sendCredentials);
        }

        $scope.authError = null;
        $scope.isLoading = true;
        // Try to login
        $scope.loginAct = function (credentials) {
            AuthExtendedService.login(credentials).then(function (user) {
                authService.loginConfirmed(user);
                $state.go('app.rmpa-authUser-usersettings');

                // notificationFactory.success('Welcome back!');
            }, function(error) {
                console.error(error);
                $scope.authError = error.statusText;

                $scope.isLoading = false;
                //notificationFactory.error('Login failed');
            });
        };

        // Force actual login
        $scope.loginAct(sendCredentials);
    };
});