/**
 * Created by nover on 16/12/14.
 */
'use strict';
var App = require('../../app');

App.controller('DashboardLogExportController', function ($scope, $log, $stateParams, DashboardService, surveyList, deps) {
    $scope.data = deps.data;
    $scope.surveys = surveyList.data.surveys;


    var currentDate = new Date();
    var currentDateDD = currentDate.getDate();
    var currentDateMM = currentDate.getMonth()+1; //January is 0!
    var yyyy = currentDate.getFullYear();
    if(currentDateDD<10){
        currentDateDD='0'+currentDateDD;
    }
    if(currentDateMM<10){
        currentDateMM='0'+currentDateMM;
    }
    var dateTo = yyyy+'-'+currentDateMM+'-'+currentDateDD;


    var previousDay = new Date();
    previousDay.setDate(previousDay.getDate()-7);
    var previousDayDD = previousDay.getDate();
    var previousDayMM = previousDay.getMonth()+1; //January is 0!
    var yyyy = previousDay.getFullYear();
    if(previousDayDD<10){
        previousDayDD='0'+currentDateDD;
    }
    if(previousDayMM<10){
        previousDayMM='0'+previousDayMM;
    }
    var dateFrom = yyyy+'-'+previousDayMM+'-'+previousDayDD;

    $scope.filters = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        survey: '',
        hideEmptyMessage: false
    };

    $scope.doFilter = function(filters) {
        $scope.data.loading = true;
        var promiseRespondentList = DashboardService.getLogExport($scope.data.pagination.currentPage, filters);
        promiseRespondentList.then(function(value) {
            $scope.data.loading = false;
            $scope.data = value.data;
        });
    }

    $scope.pageChanged = function(filters) {
        $scope.data.loading = true;
        var promiseRespondentList = DashboardService.getLogExport($scope.data.pagination.currentPage, filters);
        promiseRespondentList.then(function(value) {
            $scope.data.loading = false;
            // $log.debug(value.data);
            $scope.data = value.data;
        });
    };

});