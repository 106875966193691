// config
var angular = require('angular');
var App = require('./app');

App
  .config(
    [        '$controllerProvider', '$compileProvider', '$filterProvider', '$provide', '$breadcrumbProvider',
    function ($controllerProvider,   $compileProvider,   $filterProvider,   $provide, $breadcrumbProvider) {
        
        // lazy controller, directive and service
        app.controller = $controllerProvider.register;
        app.directive  = $compileProvider.directive;
        app.filter     = $filterProvider.register;
        app.factory    = $provide.factory;
        app.service    = $provide.service;
        app.constant   = $provide.constant;
        app.value      = $provide.value;

        $breadcrumbProvider.setOptions({
            templateUrl: 'templates/blocks/breadcrumb.html',
            includeAbstract: false
        })
    }
  ])
  .config(['$translateProvider', function($translateProvider){
    // Register a loader for the static files
    // So, the module will search missing translation tables under the specified urls.
    // Those urls are [prefix][langKey][suffix].
    $translateProvider.useStaticFilesLoader({
      prefix: 'js/l10n/',
      suffix: '.js'
    });
    // Tell the module what language to use by default
    $translateProvider.preferredLanguage('en');
    // Tell the module to store the language in the local storage
    $translateProvider.useLocalStorage();
  }]);