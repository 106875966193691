/**
 * Created by nover on 08/01/15.
 */
var App = require('../../app');
var ui = require('../ui');

App.controller('ChainListController', function ($scope, $log, $state, $modal, chainList, Session) {
    $scope.chains = chainList.data.chains;
    // $log.debug($scope.chains);

    $scope.userInfo = Session.user;

    var role = $scope.userInfo.role;

    if(role.indexOf("admin") === 0) {
        $scope.role = "admin";
    }
    else if(role.indexOf("admin") !== -1) {
        $scope.role = "member_admin";
    }
    else {
        $scope.role = "user";
    }

    $scope.openAddChainDialog = function(size){
        var modalInstance = $modal.open({
            templateUrl: 'app.chain.modal.addchain.html',
            controller: 'CustomerAddModalController',
            size: size
        });

        modalInstance.result.then(function (data) {
            if ($scope.chains) {
                $scope.chains.push(data);
            }
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
});

