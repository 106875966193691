/**
 * Created by nover on 16/12/14.
 */
'use strict';
var angular = require('angular');
angular.module('app').factory('InfoboardService', function ($http, $log) {
    var InfoboardService = {};

    InfoboardService.getInfoboard = function (chainName) {
        return $http.get('/api/infoboard/' + chainName);
    };

    return InfoboardService;
});