/**
 * Created by nover on 20/12/14.
 */
var App = require('../../app');
App
    .config(
    ['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                // Authentication / access functions
                .state('lockme', {
                    url: '/lockme',
                    templateUrl: 'templates/page_lockme.html'
                })
                .state('access', {
                    url: '/access',
                    abstract: true,
                    template: '<div ui-view class="fade-in-right-big smooth"></div>'
                })
                .state('access.signin', {
                    url: '/signin',
                    controller: 'SigninFormController',
                    templateUrl: 'templates/page_signin.html'
                })
                .state('access.forgotpwd', {
                    url: '/forgotpwd',
                    templateUrl: 'templates/page_forgotpwd.html'
                })
                .state('access.resetpwd', {
                    url: '/resetpwd?code',
                    templateUrl: 'templates/page_resetpwd.html',
                    controller: function($scope, $stateParams) {
                        $scope.code = $stateParams.code;
                    }
                })
                .state('access.404', {
                    url: '/404',
                    templateUrl: 'templates/page_404.html'
                })
        }
    ]);