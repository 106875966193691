/**
 * Created by nover on 16/12/14.
 */
'use strict';
var angular = require('angular');
angular.module('app').factory('AuthExtendedService', function ($http, $log, Session) {
    var AuthExtendedService = {};
    AuthExtendedService.login = function (credentials) {
        return $http
            .post('/api/auth/login', credentials, {ignoreAuthModule: true})
            .then(function (res) {
                return res.data.user;
            });
    };

    AuthExtendedService.logout = function () {
        return $http
            .get('/api/auth/logout')
            .then(function () {
                Session.destroy();
                return true;
            });
    };

    AuthExtendedService.userInfo = function () {
        if (Session.id === null) {
            return $http
                .get('/api/auth')
                .then(function (res) {
                    // $log.debug(res);
                    // $log.debug('Calling backend user info route');

                    Session.create(res.data.id, res.data.user);
                    return Session;
                });
        } else {
            // $log.debug('Returning cached version of credentials');
            return Session;
        }
    };

    AuthExtendedService.forgotPwd = function (credentials) {
        return $http
            .post('/api/auth/forgotPwd', credentials, {ignoreAuthModule: true});
    };

    AuthExtendedService.resetPwd = function (credentials) {
        return $http
            .post('/api/auth/resetPwd', credentials, {ignoreAuthModule: true});
    };

    AuthExtendedService.createPassword = function (credentials) {
        return $http
            .post('/api/auth/createpassword', credentials, {ignoreAuthModule: true});
    };
    
    return AuthExtendedService;
});
