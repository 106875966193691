/**
 * Created by nover on 08/01/15.
 */
var chainDataCtrl = require('./chainDataCtrl');
var App = require('../../app');

App.controller('ChainEditController', function ($scope, $log, $window, $modal, ChainService, ChainDataService,
                                                toaster, chain, chainReferences, promise, Session) {
    // $log.debug('Chain edit controller - data: ');
    // $log.debug(chain);

    $scope.chain = chain.data;
    $scope.chainReferences = chainReferences.data;

    $scope.goBack = function () {
        $window.history.back();
    };

    $scope.userInfo = Session.user;

    var role = $scope.userInfo.role;

    if(role.indexOf("admin") === 0) {
        $scope.role = "admin";
    }
    else if(role.indexOf("admin") !== -1) {
        $scope.role = "member_admin";
    }
    else {
        $scope.role = "user";
    }

    $scope.save = function (chain) {
        ChainService.updateChain(chain)
            .then(function (data) {
                toaster.pop('success', 'Changes saved', 'Your changes to the chain have been saved');
            })
            .catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.openAddUserDialog = function (size) {
        var modalInstance = $modal.open({
            templateUrl: 'app.chain.modal.addchainuser.html',
            controller: 'CustomerUserAddModalController',
            size: size
        });

        modalInstance.result.then(function (data) {
            if ($scope.chainReferences) {
                $scope.chainReferences.users.push(data);
            }
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };

    $scope.openAddCompanyDialog = function (size) {
        var modalInstance = $modal.open({
            templateUrl: 'app.chain.modal.addcompany.html',
            controller: 'CustomerLocationAddModalController',
            size: size
        });

        modalInstance.result.then(function (data) {
            if ($scope.chainReferences) {
                if(!$scope.chainReferences.companies){
                    $scope.chainReferences.companies = [];
                }
                
                $scope.chainReferences.companies.push(data);
            }
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };

    $scope.addValidationRule = function() {
        $scope.chain.validationRules.push({
            target: '',
            rule: ''
        });
    };

    $scope.removeValidationRule = function(index) {
        $scope.chain.validationRules.splice(index, 1);
    };

    $scope.removeExtraColumn = function(index) {
        $scope.chain.extraColumns.splice(index, 1);
    };

    $scope.addExtraColumn = function() {
        if(typeof $scope.chain.extraColumns === "undefined") {
            $scope.chain.extraColumns = [];
        }
        $scope.chain.extraColumns.push({
            databasekey: '',
            displayname: ''
        });
    };

    chainDataCtrl.getRespondentList($scope, $log, $modal, promise, ChainDataService, toaster, $window);
});