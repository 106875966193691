/**
 * Created by nover on 08/01/15.
 */
var App = require('../../app');

App.controller('CustomerUserAddModalController', function ($scope, $log, $modalInstance, $stateParams, ChainService, toaster) {
    // $log.debug('add customer user modal controller exec');


    $scope.ok = function (user) {
        // $log.debug(user);

        ChainService.createChainUser($stateParams.id, user)
            .then(function (data) {
                // $log.debug(data);
                $modalInstance.close(data.data);
                toaster.pop('success', 'User added', 'The user was successfully added to the system');
            }).catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
