/**
 * Created by nover on 08/01/15.
 */
var App = require('../../app');

App.controller('CustomerLocationAddModalController', function ($scope, $log, $modalInstance, $stateParams, ChainService, toaster) {
    // $log.debug('add customer location modal controller exec');


    $scope.ok = function (company) {
        // $log.debug(company);

        ChainService.createCompany($stateParams.id, company)
            .then(function (data) {
                // $log.debug(data);
                toaster.pop('success','Location created', 'The company was successfully created');
                $modalInstance.close(data.data);

            }).catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
