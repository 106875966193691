/**
 * Created by nover on 16/12/14.
 */
var App = require('../../app');
App.service('Session', function () {
    this.id = null;
    this.create = function (sessionId, user) {
        // console.log(user, "user");
        this.id = sessionId;
        this.user = user;
    };
    this.destroy = function () {
        this.id = null;
        this.user = null;
    };
    return this;
});