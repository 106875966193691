/**
 * Created by nover on 19/02/15.
 */
var App = require('../../app');

App.factory('ToolService', function ($http, $log) {
    var ToolService = {};

    /**
     * List syncronizable data
     * @returns {HttpPromise}
     */
    ToolService.getSynchronizableData = function (synckey) {
        return $http.get('/api/chainData/syncdata/' + synckey);
    };

    /**
     * Synchronize the data with given synckey
     * @param synckey
     */
    ToolService.synchronizeData = function (synckey) {
        return $http.post('/api/chainData/syncdata/' + synckey);
    };

    /**
     * List syncronizable data
     * @returns {HttpPromise}
     */
    ToolService.getSynchronizableDataWithErrorCode = function (synckey, errorCode) {
        return $http.get('/api/tools/release-by-synckey/' + synckey +'/' + errorCode);
    };

    /**
     * Synchronize the data with given synckey
     * @param synckey
     */
    ToolService.synchronizeDataWithErrorCode = function (synckey, errorCode) {
        return $http.post('/api/tools/release-by-synckey/' + synckey +'/' + errorCode);
    };

    /**
     * List the answer with given key
     * @param flipkey
     * @returns {*}
     */
    ToolService.getFlippableData = function (flipkey) {
        return $http.get('/api/chainData/answerflip/' + flipkey);
    };

    /**
     * Flip the answer with given key
     * @param flipkey
     * @returns {*}
     */
    ToolService.flipAnswer = function (flipkey) {
        return $http.post('/api/chainData/answerflip/' + flipkey);
    };


    /**
     * Add a new auth user to the system with company user as its company
     * @param data
     * @returns {HttpPromise}
     */
    ToolService.releaseByRmKey = function(data){
        return $http.post('/api/tools/alka/releaserespondentbyrmkey', data);
    };

    return ToolService;
});