/**
 * Created by nover on 07/01/15.
 */

require('./chainService');
require('./chainUserService');
require('./chainDataService');
require('./companyService');

require('./app.router.chain.js');

require('./chainAddModalCtrl');
require('./chainUserAddModalCtrl');
require('./companyAddModalCtrl');
require('./chainUserChangePasswordModalCtrl');

require('./chainListCtrl');
require('./chainEditCtrl');
require('./chainUserEditCtrl');

require('./chainDataRespondentEditModalCtrl');

require('./companyEditCtrl');