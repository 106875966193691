/**
 * Created by ds on 5/25/16.
 */
var App = require('../../app');

App
    .config(
        ['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('app.tool-datasync', {
                        url: '/tool/datasync',
                        templateUrl: 'templates/app.tool.datasync.html',
                        controller: 'ToolDataSyncController',
                        ncyBreadcrumb:{
                            label:'Data Synchronizer'
                        }
                    })
                    .state('app.tool-answerflipper', {
                        url: '/tool/answerflip',
                        templateUrl: 'templates/app.tool.flipanswer.html',
                        controller: 'ToolAnswerFlipController',
                        ncyBreadcrumb:{
                            label:'Answer Flipper'
                        }
                    })
            }
        ]);