/**
 * Created by nover on 20/12/14.
 */
var App = require('../../app');

App
    .config(
    ['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.dashboard-aggregated', {
                    url: '/dashboard/aggregated',
                    templateUrl: 'templates/app.dashboard.aggregated.html',
                    controller: 'DashboardAggregatedController',
                    ncyBreadcrumb:{
                        label:'Aggregated dash'
                    }
                })
                .state('app.dashboard-home', {
                    url: '/dashboard/home',
                    templateUrl: 'templates/app.dashboard.home.html',
                    controller: 'DashboardHomeController',
                    ncyBreadcrumb:{
                        label:'Home dash'
                    },
                    resolve: {
                        surveyList: function (SurveyService) {
                            return SurveyService.list();
                        }
                    }
                })
                .state('app.dashboard-chain', {
                    url: '/dashboard/chain',
                    templateUrl: 'templates/app.dashboard.chain.html',
                    controller: 'DashboardChainController',
                    ncyBreadcrumb:{
                        label:'Group dash'
                    },
                    resolve: {
                        customerMetrics: function (DashboardService) {
                            return DashboardService.getChainMetrics();
                        }
                    }
                })
                .state('app.dashboard-flow', {
                   url: '/dashboard/flow',
                   templateUrl: 'templates/app.dashboard.flow.html',
                   controller: 'DashboardFlowController',
                   ncyBreadcrumb:{
                       label:'Flow dash'
                   },
                })
                .state('app.dashboard-flow-v2', {
                    url: '/dashboard/flow-v2',
                    templateUrl: 'templates/app.dashboard.flow.v2.html',
                    controller: 'DashboardFlowV2Controller',
                    ncyBreadcrumb:{
                        label:'Data Flow'
                    },
                })
                .state('app.dashboard-company', {
                    url: '/dashboard/chain/company/:id',
                    templateUrl: 'templates/app.dashboard.company.html',
                    controller: 'DashboardCompanyController',
                    ncyBreadcrumb:{
                        label:'Group Location Dash',
                        parent: 'app.dashboard-chain'
                    },
                    resolve: {
                        deps: function (DashboardService, $stateParams) {
                            return DashboardService.getCompanyMetrics($stateParams.id);
                        }
                    }
                })
                .state('app.dashboard-survey', {
                    url: '/dashboard/survey',
                    templateUrl: 'templates/app.dashboard.survey.html',
                    controller: 'DashboardSurveyController',
                    ncyBreadcrumb:{
                        label:'Group Survey dash'
                    },
                    resolve: {
                        deps: function (DashboardService, $stateParams) {
                            return DashboardService.getSurveys();
                        }
                    }
                })
                .state('app.dashboard-log-import', {
                    url: '/dashboard/importJob',
                    templateUrl: 'templates/app.dashboard.log.import.html',
                    controller: 'DashboardLogImportController',
                    ncyBreadcrumb:{
                        label:'Import Job Log listing'
                    },
                    resolve: {
                        deps: function (DashboardService, $stateParams) {
                            return DashboardService.getLogImport(1);
                        },
                        surveyList: function (SurveyService) {
                            return SurveyService.list();
                        }
                    }
                })
                .state('app.dashboard-log-export', {
                    url: '/dashboard/exportJob',
                    templateUrl: 'templates/app.dashboard.log.export.html',
                    controller: 'DashboardLogExportController',
                    ncyBreadcrumb:{
                        label:'Export Job Log listing'
                    },
                    resolve: {
                        deps: function (DashboardService, $stateParams) {
                            return DashboardService.getLogExport(1);
                        },
                        surveyList: function (SurveyService) {
                            return SurveyService.list();
                        }
                    }
                })
                .state('app.dashboard-log-datacollection', {
                    url: '/dashboard/importDataCollection',
                    templateUrl: 'templates/app.dashboard.log.datacollection.html',
                    controller: 'DashboardLogDataCollectionController',
                    ncyBreadcrumb:{
                        label:'DataCollection Job Log listing'
                    },
                    resolve: {
                        deps: function (DashboardService, $stateParams) {
                            return DashboardService.getLogDataCollection(1);
                        }
                    }
                })
                .state('app.dashboard-log-reporting', {
                    url: '/dashboard/exportReporting',
                    templateUrl: 'templates/app.dashboard.log.reporting.html',
                    controller: 'DashboardLogReportingController',
                    ncyBreadcrumb:{
                        label:'Reporting Job Log listing'
                    },
                    resolve: {
                        deps: function (DashboardService, $stateParams) {
                            return DashboardService.getLogReporting(1);
                        }
                    }
                })
                .state('app.dashboard-log-create-indexes', {
                    url: '/dashboard/indexes/logs',
                    templateUrl: 'templates/app.dashboard.log.indexes.html',
                    controller: 'DashboardLogCreateIndexesCollectionController',
                    ncyBreadcrumb:{
                        label:'Reporting Job Log listing'
                    },
                    resolve: {
                        deps: function (DashboardService, $stateParams) {
                            return DashboardService.getLogIndexesGeneration(1);
                        }
                    }
                })
        }
    ]);