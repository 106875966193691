'use strict';

var angular = require('angular');
var uiload = require('./app/ui/ui-load');

var ngAnimate = require('angular-animate');
var ngCookies = require('angular-cookies');
var ngResource= require('angular-resource');
var ngSanitize = require('angular-sanitize');
var ngTouch = require('angular-touch');
var ngStorage = require('angular-storage');
var ngTranslate = require('angular-translate');
var uirouter = require('angular-ui-router');
var uibootstrap = require('angular-bootstrap');
var uiutils = require('angular-ui-utils');
var uiselect = require('angular-ui-select');
var lazyload = require('ocLazyLoad');
var authInterceptor = require('http-auth-interceptor'); // used for enabling auth in the ui.
var gravatar = require('angular-gravatar');
var md5 = require('md5');
var toaster = require('angular-toaster');
var breadcrumb = require('angular-breadcrumb');

require('./app/translate');

var app = angular.module('app', [
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngTouch',
    'ngStorage',
    'ui.router',
    'ui.bootstrap',
    'ui.select',
    'ui.load',
    'ui.jq',
    'ui.validate',
    'oc.lazyLoad',
    'pascalprecht.translate',
    'http-auth-interceptor',
    'md5',
    'ui.gravatar',
    'toaster',
    'ncy-angular-breadcrumb'
]);

module.exports = app;