/**
 * Created by nover on 19/02/15.
 */
var App = require('../../app');

App.controller('ToolDataSyncConfirmModalController', function ($scope, $log, $modalInstance, ToolService, toaster, synckey) {
    $log.debug('synchronize data modal controller');

    // Get all synchronizable data
    $scope.sync = {};
    $scope.sync.busy = true;
    $scope.sync.data = {};

    // First get the list of all synchronizable data
    var apiReq = ToolService.getSynchronizableData(synckey);
    apiReq.then(function (value) {
        $scope.sync.data = value.data.chainData;
        $log.info($scope.sync.data);

        $scope.sync.busy = false;
    });

    $scope.release = function () {
        $scope.sync.processing = true;
        var apiReq = ToolService.synchronizeData(synckey);
        apiReq
            .then(function (data) {
                toaster.pop('success', 'Data is synchronized', 'Data synchronization has been successfully done!');
                $scope.sync.processing = false;
                $modalInstance.dismiss('cancel');
            })
            .catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to synchronize', error.data.msg + ' (HTTP: ' + error.status + ')');
                $scope.sync.processing = false;
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
