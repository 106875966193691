/**
 * Created by nover on 19/02/15.
 */

var App = require('../../app');
var ui = require('../ui');

App.controller('SurveyListController', function ($scope, $log, $state, $modal, surveyList) {
    $scope.surveys = surveyList.data.surveys;

    $log.debug('survey edit controller');
    $log.debug($scope.surveys);

    $scope.listJustActive = true;

    $scope.openAddSurveyDialog = function(size){
        var modalInstance = $modal.open({
            templateUrl: 'app.survey.modal.add.html',
            controller: 'SurveyAddModalController',
            size: size
        });

        modalInstance.result.then(function (data) {
            if ($scope.surveys) {
                $scope.surveys.push(data);
            }
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
});

