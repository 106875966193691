'use strict';
var app = require('../../app');
/* Controllers */
// signin controller
app.controller('SigninFormController', ['$scope', '$http', '$state', 'authService', 'AuthExtendedService', function ($scope, $http, $state, authService, AuthExtendedService) {
    $scope.user = {};
    $scope.authError = null;
    $scope.app.settings.headerFixed = true;
    $scope.credentials = {
        username: '',
        password: ''
    };

    $scope.login = function (credentials, domain) {
        var sendCredentials = {
            email: credentials.email,
            password: credentials.password,
        }

        // console.log(domain);
        if(domain !== undefined) {
            sendCredentials.email += domain;
            // console.log(sendCredentials);
        }

        $scope.authError = null;
        $scope.isLoading = true;
        // Try to login
        $scope.loginAct = function (credentials) {
            AuthExtendedService.login(credentials).then(function (user) {
                // console.log(user);
                authService.loginConfirmed(user);
                $state.go('app.dashboard-home');

                // notificationFactory.success('Welcome back!');
            }, function(error) {
                console.error(error);
                $scope.authError = error.statusText;

                $scope.isLoading = false;
                //notificationFactory.error('Login failed');
            });
        };

        // Force actual login
        $scope.loginAct(sendCredentials);
    };
}]);
