/**
 * Created by nover on 15/01/15.
 */
var App = require('../../app');
App
    .config(
    ['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.rmpa-authUser-list', {
                    url: '/authUser/list',
                    templateUrl: 'templates/app.authUser.list.html',
                    controller: 'AuthUserListController',
                    ncyBreadcrumb: {
                        label: 'AuthUser list'
                    }
                })
                .state('app.rmpa-authUser-edit', {
                    url: '/authUser/edit/:id',
                    templateUrl: 'templates/app.authUser.edit.html',
                    controller: 'AuthUserEditController',
                    ncyBreadcrumb: {
                        label: 'AuthUser Edit'
                    },
                    resolve: {
                        authUser: function ($stateParams, AuthUserService) {
                            return AuthUserService.getAuthUser($stateParams.id);
                        }
                    }
                })
                .state('app.rmpa-authUser-usersettings', {
                    url: '/authUser/usersettings',
                    templateUrl: 'templates/app.authUser.edit.html',
                    controller: 'UserSettingsEditController',
                    ncyBreadcrumb:{
                        label:'User Settings'
                    },
                    resolve: {
                        authUser: function (UserSettingsService) {
                            return UserSettingsService.getAuthUser();
                        }
                    }
                })
        }
    ]);