/**
 * Created by ds on 9/3/15.
 */

var App = require('../../app');

App.controller('RespondentEditModalController', function ($scope, $log, $modalInstance, $stateParams, ChainDataService,
                                                          toaster, respondent, promise) {
    // $log.debug(respondent, 'edit respondent modal controller exec');
    $scope.respondent = {};
    $scope.respondent.loading = true;
    $scope.editables = {};

    var respondentDetails = promise.getRespondent(respondent);
    respondentDetails.then(function(value) {
        /// $log.debug(value.data);
        $scope.companyOptions = value.data.companyOptions;
        $scope.editables = value.data.editables;
        // $log.debug($scope.companyOptions);
        $scope.respondent = value.data.respondent;
        $scope.respondent.loading = false;
    });

    $scope.ok = function (respondent, editables) {
        respondent.editables = editables;
        // $log.debug(respondent);

        ChainDataService.updateRespondent(respondent.id, respondent)
            .then(function (data) {
                // $log.debug(data, 'data send to update');
                $modalInstance.close(data.data);
                toaster.pop('success', 'Respondent edited', 'The respondent was successfully edited');
            }).catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
