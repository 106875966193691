/**
 * Created by nover on 19/02/15.
 */

var App = require('../../app');
var ui = require('../ui');
var lunr = require('lunr');

App.controller('SurveyEditController', function ($scope, $log, $window, $state, $modal, $stateParams, toaster, SurveyService, CompanyService, SurveyGizmoService, survey, Session) {

    $scope.survey = survey.data;
    $scope.userInfo = Session.user;
    $scope.programmerEmail = SurveyService.programmerEmail;
    $scope.busy = true;

    $scope.customFields = null;
    $scope.loadingDataFromSurveyGizmo = false;
    $scope.loadSurveyGizmoCustomFields = function() {
         if (!$scope.customFields) {
            $scope.loadingDataFromSurveyGizmo = true;
            SurveyGizmoService.customFieldsList().then(function (res) {
                $scope.customFields = res.data;
                // $scope.loadingDataFromSurveyGizmo = false;
                setTimeout(function() {
                    $scope.$digest();
                }, 5000);

            })
            .catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to list companies', 'An error occurred while listing the companys from the server');
            });
        } else {
             $scope.loadingDataFromSurveyGizmo = false;
        }
    };

    $scope.backgroundQuestions = null;
    $scope.loadSurveyGizmoPageBackgroundQuestions = function(survey) {
        if (!$scope.backgroundQuestions) {
            $scope.loadingDataFromSurveyGizmo = true;
            SurveyGizmoService.pageBackgroundQuestions(survey.extraData.surveyGizmoSurveyId).then(function (res) {
                $scope.backgroundQuestions = res.data;

                setTimeout(function() {
                    $scope.$digest();
                }, 5000);

                $scope.loadingDataFromSurveyGizmo = false;
            })
            .catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to list companies', 'An error occurred while listing the companys from the server');
            });
        } else {
            $scope.loadingDataFromSurveyGizmo = false;
        }
    };

    var role = $scope.userInfo.role;

    if(role.indexOf("admin") === 0) {
        $scope.role = "admin";
    }
    else if(role.indexOf("admin") !== -1) {
        $scope.role = "member_admin";
    }
    else {
        $scope.role = "user";
    }

    $scope.addBlacklistedSMS = function () {
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' ' +  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

        console.log(date);

        if(!$scope.survey.extraData.blacklist) {
            $scope.survey.extraData.blacklist = [];
        }
        $scope.survey.extraData.blacklist.push({
            mobile_email: '',
            comment: 'hates invites',
            created_at: date,
            created_by: 'rmpa'

        });
    };

    $scope.removeBlacklistedSMS = function (index) {
        $scope.survey.extraData.blacklist.splice(index, 1);
    };

    $scope.companies = [];
    $scope.companies.selected = null;

    CompanyService.listCompanies()
        .then(function (res) {
            $scope.allCompanies = res.data.companies; // save reference for a list of all company....
            $scope.companies = res.data.companies;
            $scope.companies.selected = null;

            // build lunr search index
            var index = lunr(function () {
                this.field('name');
                this.ref('id');
            });
            $scope.allCompanies.forEach(function (item) {
                $log.debug(item, "adding item to lunr index");
                index.add(item);
            });
            $scope.index = index;
        })
        .catch(function (error) {
            $log.error(error);
            toaster.pop('error', 'Unable to list companies', 'An error occurred while listing the companys from the server');
        });

    $scope.goBack = function () {
        $window.history.back();
    };

    $scope.save = function (survey) {
        SurveyService.update(survey)
        .then(function (data) {
            toaster.pop('success', 'Changes saved', 'Your changes to the survey have been saved');
            $scope.editable = false;
            $scope.cancelNewField();
        })
        .catch(function (error) {
            $log.error(error);
            toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
        });
    };

    $scope.refreshCompanyList = function (searchText) {
        if (searchText === '') {
            if ($scope.allCompanies) {
                $scope.companies = $scope.allCompanies;
            }
            return;
        }
        var Enumerable = require('linq');

        $log.debug(searchText, "refresh company list");
        var searchResults = $scope.index.search(searchText);
        $scope.companies = [];

        var query = Enumerable.From($scope.allCompanies);
        searchResults.forEach(function (result) {
            $scope.companies.push(query.SingleOrDefault(null, function (x) {
                return x.id === result.ref
            }));
        });
    };

    $scope.addCompanyToSurvey = function () {
        $log.debug($scope.companies.selected, 'add company to survey');
        if (!$scope.companies.selected) {
            toaster.pop('error', 'You need to select a company');
            return;
        }
        SurveyService.addCompany($stateParams.id, $scope.companies.selected.id)
            .then(function (res) {
                $log.debug('Added company to survey');
                toaster.pop('success', 'Added to survey', 'The company was successfully added to the survey');
                $scope.survey = res.data;
            });
    };

    $scope.removeCompanyFromSurvey = function(companyId) {
        $log.debug(companyId, 'remove company from survey');
        SurveyService.removeCompany($stateParams.id, companyId)
            .then(function (res) {
                $log.debug('Removed company from survey');
                toaster.pop('success', 'Removed from survey', 'The company was successfully removed from the survey');
                $scope.survey = res.data;
            })
    };

    $scope.addValidationRule = function() {
        $scope.survey.validationRules.push({
            target: '',
            rule: ''
        });
    };

    $scope.removeValidationRule = function(index) {
        $scope.survey.validationRules.splice(index, 1);
    };

    $scope.goBack = function () {
        $window.history.back();
    };

    //////// CREATE & EDIT FIELDS //////////////////////
    $scope.editable = false;
    $scope.showAddNewFieldForm = false;
    $scope.addNewField = function () {
        $scope.showAddNewFieldForm = true;
    };
    $scope.cancelNewField = function () {
        initNewMappingObject()
        $scope.showAddNewFieldForm = false;
    };
    function initNewMappingObject () {
        $scope.newMapping = {
            'header': null,
            'dbFieldName': null,
            'existingMapping': null,
            'customField': null,
            'description': null,
            'deleted': null

        };
    }
    initNewMappingObject();
    $scope.saveNewField = function (key, newMapping) {
        if (newMapping.header != null) {
            if(!(newMapping.header in $scope.survey.extraData.dataStructureV2[key].mapping)) {
                $scope.survey.extraData.dataStructureV2[key].mapping[newMapping.header] = {
                    customField: newMapping.customField,
                    dbFieldName: newMapping.dbFieldName,
                    deleted: newMapping.delete,
                    description: newMapping.description,
                    existingMapping: newMapping.existingMapping,
                    sgQuestion: newMapping.sgQuestion
                };
                $scope.cancelNewField();
                return true;
            } else {
                toaster.pop('error', 'Unable to save field ' + newMapping.header + ', already exists.');
                return false;
            }
        } else {
            toaster.pop('error', 'Field header is required.');
            return false;
        }
        return false;
    };
    $scope.editFields = function () {
        $scope.cancelNewField();
        $scope.editable = true;
        console.log('Edit Fields');
    };
    $scope.cancelEditFields = function () {
        $scope.editable = false;
        console.log('Cancel edit fields');
    };
    $scope.removeField = function (header, mapping) {
        delete mapping[header];
    };
});
