/**
 * Created by nover on 08/01/15.
 */
var App = require('../../app');

App.controller('CustomerAddModalController', function ($scope, $log, $modalInstance, ChainService, toaster) {
    $log.debug('add customer modal controller exec');


    $scope.ok = function (customer) {
        $log.debug(customer);

        ChainService.createChain(customer)
            .then(function (data) {
                $log.debug(data);
                $modalInstance.close(data.data);

            }).catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
