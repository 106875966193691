/**
 * Created by nover on 16/12/14.
 */
'use strict';
var App = require('../../app');

App.controller('DashboardFlowController', function ($scope, $log, DashboardService, $interval, $filter) {

    $scope.today = function() {
        $scope.selectedDate = new Date();
    };
    $scope.today();

    var fromDate = $filter('date')($scope.selectedDate,'dd-MM-yyyy');
    var intervalTimer;
    var busy = true;

    $scope.clear = function () {
        $scope.selectedDate = null;
    };

    // Disable weekend selection
    $scope.disabled = function(date, mode) {
        return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
    };

    $scope.toggleMin = function() {
        $scope.minDate = $scope.minDate ? null : new Date();
    };
    $scope.toggleMin();

    $scope.open = function($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened = true;
    };

    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };

    $scope.formats = ['dd-MM-yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    $scope.dateSelected = function () {
        if (!busy)
        {
            var apiReq = DashboardService.getFlowMetrics($filter('date')($scope.selectedDate,'dd-MM-yyyy'));
            busy = true;
            apiReq.then(function (value) {
                delete value.data.error;
                delete value.data.status;
                $scope.companies = value.data;
                busy = false;
            });
        }
    }

    var outerfunc = function() {
        intervalTimer = $interval(function() {
            //No reason to fire another request if the last one has not finished
            if (!busy)
            {
                var apiReq = DashboardService.getFlowMetrics($filter('date')($scope.selectedDate,'dd-MM-yyyy'));
                busy = true;
                apiReq.then(function (value) {
                    delete value.data.error;
                    delete value.data.status;
                    $scope.companies = value.data;
                    busy = false;
                });
            }
        }, 15000);
    };

    //Initial request
    var apiReq = DashboardService.getFlowMetrics(fromDate);
    apiReq.then(function (value) {
        delete value.data.error;
        delete value.data.status;
        $scope.companies = value.data;
        busy = false;
    });
    outerfunc();

    $scope.$on("$destroy", function() {
        if (intervalTimer) {
            $interval.cancel(intervalTimer);
        }
    });
});