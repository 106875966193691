'use strict';

var App = require('../../app');

App.controller('ToolDataSyncController', function ($scope, $log, $modal, $http, $state, toaster, authService, ToolService, ToolAlkaService) {
    $scope.sync = {};
    $scope.sync.key = "";
    $scope.synchronize = function(sync, size){
        var modalInstance = $modal.open({
            templateUrl: 'app.tool.datasync.modal.confirmation.html',
            controller: 'ToolDataSyncConfirmModalController',
            size: size,
            resolve: {
                synckey: function () {
                    return sync.key;
                }
            }
        });

        modalInstance.result.then(function (data) {
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
    
	$scope.data = {};
	$scope.data.rmkey = "";
    $scope.releaseByRmKey = function(data){
        ToolService.releaseByRmKey(data)
            .then(function(data){
                toaster.pop('success', 'Changes saved', 'Your changes have been successfully saved');
            })
            .catch(function(error){
                $log.error(error);
                toaster.pop('error', 'Error saving data', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.sync.errorCode = 1008;
    $scope.synchronizeBySyncKeyAndErrorCode = function(sync, size){

        var modalInstance = $modal.open({
            templateUrl: 'app.tool.datasync.with.errorcode.modal.confirmation.html',
            controller: 'ToolDataSyncWithErrorCodeConfirmModalController',
            size: size,
            resolve: {
                synckey: function () {
                    return sync.key;
                },
                errorCode: function () {
                    return sync.errorCode;
                }
            }
        });

        modalInstance.result.then(function (data) {
        }, function () {
            $scope.sync.key = "";
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
});