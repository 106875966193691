/**
 * Created by nover on 16/12/14.
 */
require('./app.router.dashboard');
require('./dashboardHomeCtrl');
require('./dashboardService');
require('./dashboardAggregatedCtrl');
require('./dashboardChainCtrl');
require('./dashboardFlowCtrl');
require('./dashboardFlowV2Ctrl');
require('./dashboardCompanyCtrl');
require('./dashboardSurveyCtrl');
require('./dashboardLogImportCtrl');
require('./dashboardLogExportCtrl');
require('./dashboardLogDataCollectionCtrl');
require('./dashboardLogReportingCtrl');
require('./dashboardLogCreateIndexesCollectionCtrl');
//require('./dashboardTest');
require('./percentageFilter');
