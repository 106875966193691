/**
 * Created by nover on 22/12/14.
 */
var App = require('../../app');
App
    .config(
    ['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                // Authentication / access functions
                .state('app.project-list', {
                    url: '/project/list',
                    templateUrl: 'templates/app.project.list.html',
                    controller: 'ProjectListController',
                    ncyBreadcrumb:{
                        label:'Project list'
                    },
                    resolve: {
                        projectList : function(ProjectService) {
                            return ProjectService.listProjects();
                        }
                    }
                })
                .state('app.project-edit', {
                    url: '/project/edit/:id',
                    templateUrl: 'templates/app.project.edit.html',
                    controller: 'ProjectEditController',
                    ncyBreadcrumb:{
                        label:'Project edit',
                        parent: 'app.project-list'
                    },
                    resolve: {
                        project: function($stateParams, ProjectService) {
                            return ProjectService.getProject($stateParams.id);
                        },
                        authUsers: function(AuthUserService){
                            return AuthUserService.listAuthUsers();
                        }
                    }
                })
        }
    ]);