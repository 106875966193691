/**
 * Created by nover on 19/02/15.
 */
var App = require('../../app');

App.controller('ToolAnswerFlipConfirmModalController', function ($scope, $log, $modalInstance, ToolService, toaster, flipkey) {
    $log.debug('answer flipper data modal controller');

    // Get all flippable data
    $scope.flip = {};
    $scope.flip.busy = true;
    $scope.flip.data = {};

    // First get the list of all flippable data
    var apiReq = ToolService.getFlippableData(flipkey);
    apiReq.then(function (value) {
        // console.log(value);
        $scope.flip.data = value.data.flippableAnswer;
        $log.info($scope.flip.data);

        $scope.flip.busy = false;
    }).catch(function (error) {
        $log.error(error);
        toaster.pop('error', 'Unable to find data', error.data.msg + ' (HTTP: ' + error.status + ')');
        $scope.flip.processing = false;
    });

    $scope.release = function () {
        $scope.flip.processing = true;
        var apiReq = ToolService.flipAnswer(flipkey);
        apiReq
            .then(function (data) {
                toaster.pop('success', 'Answer is flipped', 'Answer flipper has been successfully done!');
                $scope.flip.processing = false;
                $modalInstance.dismiss('cancel');
            })
            .catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to flip', error.data.msg + ' (HTTP: ' + error.status + ')');
                $scope.flip.processing = false;
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
