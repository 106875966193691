/**
 * Created by nover on 22/12/14.
 */
var App = require('../../app');
var ui = require('../ui');

App.controller('AddProjectModalController', function ($scope, $log, $modalInstance, ProjectService, authUsers) {
    $scope.authUsers = authUsers.data.users;

    $scope.ok = function (project) {
        $log.debug(project);
        $log.debug('Modal OK, DO SOMETHING');

        ProjectService.createNewProject(project)
            .then(function (data) {
                $log.debug(data);
                $modalInstance.close(data);

            }).catch(function (error) {
                $log.error(error);
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});

App.controller('ProjectListController', function ($scope, $log, $state, $modal, projectList) {
    $scope.projects = projectList.data.projects;
    $log.debug($scope.projects);

    $scope.openAddProjectDialog = function (size) {
        var modalInstance = $modal.open({
            templateUrl: 'app.project.modal.addproject.html',
            controller: 'AddProjectModalController',
            size: size,
            resolve: {
                authUsers: function (AuthUserService) {
                    return AuthUserService.listAuthUsers();
                }
            }
        });

        modalInstance.result.then(function (data) {
            if ($scope.projects) {
                $scope.projects.push(data);
            }
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
});
