/**
 * Created by nover on 16/12/14.
 * This controller is not valid anymore, need cleanup
 *
 */
'use strict';
var App = require('../../app');
//
//App.controller('DashboardAggregatedController', function ($scope, $log, DashboardService, jobMetrics, jobMetricsOverTime) {
//    $log.debug('In dashboard controller');
//
//    $scope.jobMetrics = jobMetrics.data;
//    $scope.jobMetricsOverTime = jobMetricsOverTime.data;
//});

App.controller('DashboardAggregatedController', function ($scope, $log, DashboardService, $interval) {
    // $log.debug('In dashboard controller');

    var intervalTimer;
    //var intervalTimerOverTime;
    $scope.busy = true;
    //$scope.busyOverTime = true;
    var outerfunc = function() {
        intervalTimer = $interval(function() {
            //No reason to fire another request if the last one has not finished
            if (!$scope.busy)
            {
                var apiReq = DashboardService.getJobMetrics();
                $scope.busy = true;
                apiReq.then(function (value) {
                    delete value.data.error;
                    delete value.data.status;
                    $scope.jobMetrics = value.data;
                    $scope.busy = false;
                });
            }
        }, 15000);
    };
    //var outerfuncOverTime = function() {
    //    intervalTimerOverTime = $interval(function() {
    //        //No reason to fire another request if the last one has not finished
    //        if (!$scope.busyOverTime)
    //        {
    //            var apiReq = DashboardService.getJobMetricsOverTime();
    //            $scope.busyOverTime = true;
    //            apiReq.then(function (value) {
    //                delete value.data.error;
    //                delete value.data.status;
    //                $scope.jobMetricsOverTime = value.data;
    //                $scope.busyOverTime = false;
    //            });
    //        }
    //    }, 15000);
    //};

    //Initial request
    var apiReq = DashboardService.getJobMetrics();
    apiReq.then(function (value) {
        delete value.data.error;
        delete value.data.status;
        $scope.jobMetrics = value.data;
        $scope.busy = false;
    });
    outerfunc();

    //var apiReqOvertime = DashboardService.getJobMetricsOverTime();
    //apiReqOvertime.then(function (value) {
    //    delete value.data.error;
    //    delete value.data.status;
    //    $scope.jobMetricsOverTime = value.data;
    //    $scope.busyOverTime = false;
    //    $scope.jobMetricsOverTime.uiOptions = "test";
    //});
    //outerfuncOverTime();

    $scope.$on("$destroy", function() {
        if (intervalTimer) {
            $interval.cancel(intervalTimer);
            //$interval.cancel(intervalTimerOverTime);
        }
    });
});