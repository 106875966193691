/**
 * Created by nover on 19/02/15.
 */
var App = require('../../app');

App.factory('SurveyGizmoService', function ($http, $log) {
    var surveyGizmoService = {};

    /**
     * List the existing surveys in the system
     * @returns {HttpPromise}
     */
    surveyGizmoService.customFieldsList = function () {
        return $http.get('/api/tools/apiv5-surveygizmo/custom-fields')
            .then(function (response) {
            return response;
        });
    };

    /**
     * List the existing surveys in the system
     * @returns {HttpPromise}
     */
    surveyGizmoService.pageBackgroundQuestions = function (sgSurveyId) {
        return $http.get('/api/tools/apiv5-surveygizmo/survey/' + sgSurveyId + '/surveypage/13')
            .then(function (response) {
                return response;
            });
    };

    return surveyGizmoService;
});