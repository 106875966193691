/**
 * Created by nover on 16/12/14.
 */
'use strict';
var App = require('../../app');

App.controller('MemberAdminDashboardSelectionController', function ($scope, $log, CompanyService, ChainDataService) {
    $scope.busy = true;
    $scope.department = 'all';
    $scope.show = 'all';

    $scope.chainData = [];
    $scope.chainData.selection = {
        "total": 0,
        "kcp": 0,
        "damage": 0
    };

    //Initial request
    var apiReq = CompanyService.getMyRespondentList(1, $scope.department, $scope.show);
    apiReq.then(function (value) {
        delete value.data.error;
        delete value.data.status;

        $log.debug(value);
        $scope.chainData = value.data;
        $scope.busy = false;
    });

    // Do change page towards respondent list
    $scope.pageChanged = function() {
        $scope.busy = true;
        $log.log('Page changed to: ' + $scope.chainData.pagination.currentPage);

        var promiseRespondentList = CompanyService.getMyRespondentList($scope.chainData.pagination.currentPage, $scope.department, $scope.show);
        promiseRespondentList.then(function(value) {
            $scope.busy = false;
            $log.debug(value.data);
            $scope.chainData.respondentList = value.data.respondentList;
            $scope.chainData.pagination = value.data.pagination;
            $scope.chainData.selection = value.data.selection;
        });
    };

    $scope.selectResponse = function(respondent) {
        var id = respondent.id;
        $log.log('Select response with id: ' + id);
        $log.log('Number of selected responses: ' + $scope.chainData.selection.total);
        respondent.isSelected = !respondent.isSelected;
        if(respondent.isSelected) {
            $scope.chainData.selection.total = $scope.chainData.selection.total + 1;
        }
        else {
            $scope.chainData.selection.total = $scope.chainData.selection.total - 1;
        }

        var promiseSelectResponse = ChainDataService.selectResponse(id);
        promiseSelectResponse.then(function(value) {
            var isSelected = value.data.isSelected;
            respondent.isSelected = isSelected;
            $scope.chainData.selection = value.data.selection;
        });
    }

    $scope.departmentChange = function() {
        $scope.pageChanged();
    }

    $scope.showChange = function() {
        $scope.pageChanged();
    }
});