/**
 * Created by nover on 19/02/15.
 */
var App = require('../../app');

App.factory('SurveyService', function ($http, $log) {
    var SurveyService = {};

    /**
     * List the existing surveys in the system
     * @returns {HttpPromise}
     */
    SurveyService.list = function () {
        return $http.get('/api/survey')
            .then(function (response) {
            return response;
        });
    };

    /**
     * Create a new survey in the system
     * @param survey
     * @returns {HttpPromise}
     */
    SurveyService.create = function (survey) {
        return $http.post('/api/survey', survey);
    };

    /**
     * Get a survey based off it's id
     * @param id The id of the survey to fetch
     * @returns {HttpPromise}
     */
    SurveyService.get = function (id) {
        return $http.get('/api/survey/' + id);
    };

    /**
     * Update the given survey
     * @param survey
     * @returns {HttpPromise}
     */
    SurveyService.update = function(survey) {
        return $http.put('/api/survey/' + survey.id, survey);
    };

    /**
     * Add the given customer to the survey
     * @param surveyId The id of the survey to add a customer to
     * @param customerId The id of the customer to add to the survey
     */
    SurveyService.addCompany = function (surveyId, customerId) {
        return $http.post('/api/survey/' + surveyId + '/addCompany/' + customerId, null);
    };

    SurveyService.removeCompany = function (surveyId, customerId) {
        return $http.delete('/api/survey/' + surveyId + '/addCompany/' + customerId, null);
    };

    SurveyService.programmerEmail = 'db@relationmonitor.com';

    return SurveyService;
});