/**
 * Created by nover on 09/01/15.
 */
var App = require('../../app');

App.controller('ChainUserEditController', function($scope, $log, $modal,$window, toaster, ChainUserService, user){
    // $log.debug('Chain user edit controller init...');
    // $log.debug(user);

    $scope.user = user.data;

    $scope.goBack = function () {
        $window.history.back();
    };

    $scope.save = function(user) {
        ChainUserService.updateUser(user.id, user)
            .then(function (data){
                toaster.pop('success', 'Changes saved', 'Your changes were successfully saved');
            })
            .catch(function (error){
                $log.error(error);
                toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
            })
    };

    $scope.changePassword = function(size) {
        var modalInstance = $modal.open({
            templateUrl: 'app.chain.modal.chainuserchangepassword.html',
            controller: 'CustomerUserChangePasswordModalController',
            size: size
        });

        modalInstance.result.then(function (data) {
            // $log.debug('Modal closed');
        }, function () {
            $log.debug('Modal dismissed at: ' + new Date());
        });
    }
});