/**
 * Created by nover on 09/01/15.
 */
var App = require('../../app');

App.factory('ChainUserService', function ($http, $log) {
    var ChainUserService = {};

    /**
     * Get a customer object off it's id
     * @param id The id of the customer
     * @returns {HttpPromise}
     */
    ChainUserService.getUser = function (id) {
        return $http.get('/api/chain-user/' + id);
    };

    /**
     * Update the user's password
     * @param userId The id of the user to update the password for
     * @param password The new plain-text password
     * @returns {HttpPromise}
     */
    ChainUserService.updatePassword = function(userId, password) {
        return $http.post('/api/chain-user/' + userId + '/updatePassword', password);
    };

    /**
     * Update the given user
     * @param userId The id of the user to update
     * @param user The user data
     * @returns {HttpPromise}
     */
    ChainUserService.updateUser = function(userId, user) {
        return $http.put('/api/chain-user/' + userId, user);
    };

    return ChainUserService;
});