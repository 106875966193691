/**
 * Created by nover on 22/12/14
 */
var App = require('../../app');

App.factory('UserSettingsService', function($http, $log){
    var UserSettingsService = {};

    /**
     * Get own user from the system
     * @returns {HttpPromise}
     */
    UserSettingsService.getAuthUser = function() {
        return $http.get('/api/usersettings');
    };

    /**
     * Update auth user
     * @param authUser The auth user data object including the unique id
     * @returns {HttpPromise}
     */
    UserSettingsService.updateAuthUser = function(authUser) {
        return $http.put('/api/usersettings', authUser);
    };

    return UserSettingsService;
});