'use strict';
var angular = require('angular');
var moment = require('moment');

/* Filters */
// need load the moment.js to use this filter. 
angular.module('app')
  .filter('fromNow', function() {
    return function(date) {
      if(date === null) {
        return "Never";
      }

      if(parseInt(date) === date){
        return moment.unix(date).fromNow();
      }
      // date string, normal handling
      return moment(date).fromNow();
    }
  });