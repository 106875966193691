/**
 * Created by nover on 19/02/15.
 */
var App = require('../../app');

App.controller('SurveyAddModalController', function ($scope, $log, $modalInstance, SurveyService, toaster) {
    $log.debug('add survey modal controller exec');


    $scope.ok = function (survey) {
        $log.debug(survey);

        SurveyService.create(survey)
            .then(function (data) {
                $log.debug(data);
                $modalInstance.close(data.data);

            }).catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
