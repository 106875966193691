/**
 * Created by nover on 22/12/14.
 */
var App = require('../../app');
App
    .config(
    ['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                // Authentication / access functions
                .state('app.chain-list', {
                    url: '/chain/list',
                    templateUrl: 'templates/app.chain.list.html',
                    controller: 'ChainListController',
                    ncyBreadcrumb: {
                        label: 'Chain list'
                    },
                    resolve: {
                        chainList: function (ChainService) {
                            return ChainService.listChains();
                        }
                    }
                })
                .state('app.chain-edit', {
                    url: '/chain/edit/:id',
                    templateUrl: 'templates/app.chain.edit.html',
                    controller: 'ChainEditController',
                    ncyBreadcrumb: {
                        label: 'Chain editor',
                        parent: 'app.chain-list'
                    },
                    resolve: {
                        chain: function ($stateParams, ChainService) {
                            return ChainService.getChain($stateParams.id);
                        },
                        chainReferences: function ($stateParams, ChainService) {
                            return ChainService.getChainReferences($stateParams.id);
                        },
                        promise: function ($stateParams, ChainService, ChainDataService, $q, $timeout) {
                            var metric = $q.defer();

                            $timeout(function() {
                                metric.resolve({
                                    respondentList: function(page) {
                                        return ChainService.getRespondentList($stateParams.id, page);
                                    },
                                    search: function(query, page) {
                                        return ChainService.search($stateParams.id, query, page);
                                    },
                                    getRespondent: function(id) {
                                        return ChainDataService.getRespondent(id);
                                    }
                                });
                            }, 5000);

                            return metric.promise;
                        }
                    }
                })
                .state('app.chain-user-edit', {
                    url: '/chain-user/edit/:id',
                    templateUrl: 'templates/app.chainUser.edit.html',
                    controller: 'ChainUserEditController',
                    ncyBreadcrumb: {
                        label: 'User edit',
                        parent: 'app.chain-edit'
                    },
                    resolve: {
                        user: function($stateParams, ChainUserService){
                            return ChainUserService.getUser($stateParams.id)
                        }
                    }
                })
                .state('app.company-edit', {
                    url: '/company/edit/:id',
                    templateUrl: 'templates/app.company.edit.html',
                    controller: 'CompanyEditController',
                    ncyBreadcrumb: {
                        label: 'Company edit',
                        parent: 'app.chain-edit'
                    },
                    resolve: {
                        company: function($stateParams, CompanyService){
                            return CompanyService.getCompany($stateParams.id);
                        },
                        promise: function ($stateParams, CompanyService, ChainDataService, $q, $timeout) {
                            var metric = $q.defer();

                            $timeout(function() {
                                metric.resolve({
                                    respondentList: function(page) {
                                        return CompanyService.getRespondentList($stateParams.id, page);
                                    },
                                    search: function(query, page) {
                                        return CompanyService.search($stateParams.id, query, page);
                                    },
                                    getRespondent: function(id) {
                                        return ChainDataService.getRespondent(id);
                                    },
                                    export: function(from, to) {
                                        return CompanyService.export($stateParams.id, from, to);
                                    }
                                });
                            }, 5000);

                            return metric.promise;
                        }
                    }
                })

        }
    ]);