
'use strict';
var App = require('../../app');

App.controller('DashboardHomeController', function ($scope, $log, DashboardService, $filter, $localStorage, $interval, surveyList) {

    $scope.busy = true;
    $scope.loading = false;

    $scope.filters = {
        dateFrom: new Date(),
        dateTo: new Date(),
        survey: '',
        hideJobsWithZeroImport: true,
        showLastJobOnly: false,
        limit: 5
    };
    $scope.surveys = surveyList.data.surveys;

///////////////////////////////////////////////////////////////////////////////


///From Date Picker////////
    $scope.filters.dateFrom = $filter('date')($scope.filters.dateFrom,'dd-MM-yyyy');
    $scope.filters.dateTo = $filter('date')($scope.filters.dateTo,'dd-MM-yyyy');

    $scope.clear = function () {
        $scope.filters.dateFrom = null;
        $scope.filters.dateTo = null;
    };

    // Disable weekend selection
    $scope.disabled = function(date, mode) {
        return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
    };

    $scope.toggleMin = function() {
        $scope.minDate = $scope.minDate ? null : new Date();
    };
    $scope.toggleMin();

    $scope.open = function($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened = true;
    };

    $scope.openDateTo = function($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.openedDateTo = true;
    };

    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };

    if ($localStorage.dateFrom) {
        console.log('local storage');
    }

    $scope.formats = ['dd-MM-yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
///</Date Picker>////////

//Initial request
    var apiReq = DashboardService.getHome($scope.filters);
    apiReq.then(function (value) {
        delete value.data.error;
        delete value.data.status;
        $scope.jobs = value.data.jobs;

        $scope.filters.dateFrom = value.data.filters.dates.from;
        $scope.filters.dateTo = value.data.filters.dates.to;
        $scope.busy = false;
    });

    $scope.doFilter = function(filters) {
        $scope.filters.dateFrom = $filter('date')($scope.filters.dateFrom,'dd-MM-yyyy');
        $scope.filters.dateTo = $filter('date')($scope.filters.dateTo,'dd-MM-yyyy');
        $scope.loading = true;
        var promiseRespondentList = DashboardService.getHome(filters);
        promiseRespondentList.then(function(value) {
            $scope.loading = false;
            $scope.jobs = value.data.jobs;
            $scope.filters.dateFrom = value.data.filters.dates.from;
            $scope.filters.dateTo = value.data.filters.dates.to;
        });
    };

});