/**
 * Created by ds on 4/21/16.
 */
var App = require('../../app');

App
    .config(
        ['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('infoboard', {
                        url: '/infoboard/show',
                        templateUrl: 'templates/app.infoboard.html',
                        controller: 'InfoboardController',
                        ncyBreadcrumb:{
                            label:'Infoboard'
                        }
                    })
            }
        ]);