/**
 * Created by nover on 16/12/14.
 */
var App = require('../../app');

App.controller('HeaderController', function ($scope, $log, $state, Session, AuthExtendedService) {
    $scope.userInfo = Session.user;
    $scope.app.domain = $scope.userInfo.domain;
    if($scope.userInfo.logoUrl !== null) {
        $scope.app.logo = $scope.userInfo.logoUrl;
    }
    if($scope.userInfo.gui !== null) {
        $scope.app.settings = $scope.userInfo.gui;
    }
    // console.log($scope.app.settings, "App Settings");

    $scope.logout = function () {
        AuthExtendedService.logout()
            .then(function () {
                $log.debug($scope.app.domain);
                if($scope.app.domain == "@alka.dk") {
                    $state.go('alka');
                }
                else {
                    $state.go('access.signin');
                }
            })
            .catch(function (error) {
                $log.error(error);
            });
    }
});