'use strict';

var angular = require('angular');
var App = require('./app');

/**
 * Config for the router
 */
App
    .run(
    ['$rootScope', '$state', '$stateParams',
        function ($rootScope, $state, $stateParams) {
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
        }
    ]
)
    .config(
    ['$stateProvider', '$urlRouterProvider',
        function ($stateProvider, $urlRouterProvider) {

            $urlRouterProvider
                .otherwise('/access/signin');

            $stateProvider
                .state('app', {
                    abstract: true,
                    url: '/app',
                    templateUrl: 'templates/app.html',
                    resolve: {
                        userInfo: ['AuthExtendedService',
                            function (AuthExtendedService) {
                                return AuthExtendedService.userInfo();
                            }]
                    }
                });
        }
    ]
);