/**
 * Created by nover on 16/12/14.
 */
'use strict';
var App = require('../../app');

App.controller('DashboardChainController', function ($scope, $log, DashboardService, customerMetrics) {
    // $log.debug(customerMetrics, 'In dashboard customer controller 22');

    delete customerMetrics.data.error;
    delete customerMetrics.data.status;

    // Fix bug 2 empty items because of the array of data contains 2 non number key (error and status)
    var arr = [];
    angular.forEach(customerMetrics.data, function(value, key) {
        var isFirstItem = false;
        angular.forEach(value, function(compValue) {
            if(!isFirstItem) {
                compValue.surveyName = key;
                isFirstItem = true;
            }
            arr.push(compValue);
        }, $log);
    }, $log);
    // $log.debug(arr, 'Return array');
    // $log.debug(customerMetrics.data, 'Data object');

    $scope.companies = arr;
});