/**
 * Created by nover on 15/01/15.
 */
var App = require('../../app');

App.controller('CompanyUserListController', function($scope, $log, $modal, CompanyUserService, authUser){

	$scope.busy = true;
	$scope.authUserObject = authUser.data.user;
	
	$scope.isAdmin = false;
	if (authUser.data.role == 'admin') {
		$scope.isAdmin = true;
	}
		
    $scope.authUsers = [{
        fullname: "David",
        email: "d@d.com",
        company: {
            name: "RelationMonitor"
        },
        lastLoginAt: "2016-01-01"
    }];

    //Initial request0
    var apiReq = CompanyUserService.listUsers();
    apiReq.then(function (value) {
        delete value.data.error;
        delete value.data.status;
        $scope.authUsers = value.data.users;
        $scope.surveys = value.data.surveys;
        $scope.busy = false;
    });

    $scope.openAddCompanyUserDialog = function(size){
        var modalInstance = $modal.open({
            templateUrl: 'app.companyUser.modal.addCompanyUser.html',
            controller: 'CompanyUserAddModalController',
            size: size,
            resolve: {
                surveys: function() {
                    return $scope.surveys;
                }
            }
        });

        modalInstance.result.then(function (data) {
            if ($scope.authUsers) {
                $scope.authUsers.push(data);
                
            }
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
});