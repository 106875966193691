/**
 * Created by nover on 22/12/14.
 */
var App = require('../../app');

App.factory('CompanyUserService', function($http, $log){
    var CompanyUserService = {};

    /**
     * List all company users in the system based on user's company
     * @returns {HttpPromise}
     */
    CompanyUserService.listUsers = function(){
        return $http.get('/api/companyuser');
    };

    /**
     * Add a new auth user to the system with company user as its company
     * @param authUser
     * @returns {HttpPromise}
     */
    CompanyUserService.createNewCompanyUser = function(authUser){
        return $http.post('/api/companyuser', authUser);
    };

    /**
     * Get the given auth user from the system
     * @param id The id of the auth user
     * @returns {HttpPromise}
     */
    CompanyUserService.getCompanyUser = function(id) {
        return $http.get('/api/companyuser/' + id);
    };

    /**
     * Update auth user
     * @param authUser The auth user data object including the unique id
     * @returns {HttpPromise}
     */
    CompanyUserService.updateCompanyUser = function(authUser) {
        return $http.put('/api/companyuser/' + authUser.id, authUser);
    };

    /**
     * Update auth user
     * @param authUser The auth user data object including the unique id
     * @returns {HttpPromise}
     */
    CompanyUserService.switchUserCompany = function(authUser) {
        return $http.put('/api/companyuser/switchcompany/' + authUser.id, authUser);
    };
    return CompanyUserService;
});