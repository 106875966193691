/**
 * Created by ds on 4/21/16.
 */
'use strict';
var App = require('../../app');

App.controller('InfoboardController', function ($scope, $log, InfoboardService, $interval) {
    var intervalTimer;
    var busy = true;
    $scope.infoboard = "Loading...";
    $scope.custName = "";
    var chainName = "Alka CSS";

    var outerfunc = function() {
        intervalTimer = $interval(function() {
            //No reason to fire another request if the last one has not finished
            if (!busy)
            {
                var apiReq = InfoboardService.getInfoboard(chainName);
                busy = true;
                apiReq.then(function (value) {
                    delete value.data.error;
                    delete value.data.status;
                    $scope.infoboard = value.data.display;
                    $scope.custName = value.data.custName;
                    busy = false;
                });
            }
        }, 15000);
    };

    $scope.app.settings.headerFixed = false;

    //Initial request
    var apiReq = InfoboardService.getInfoboard(chainName);
    apiReq.then(function (value) {
        $log.debug(value);
        delete value.data.error;
        delete value.data.status;
        $scope.infoboard = value.data.display;
        $scope.custName = value.data.custName;
        busy = false;
    });
    outerfunc();

    $scope.$on("$destroy", function() {
        if (intervalTimer) {
            $interval.cancel(intervalTimer);
        }
    });
});