/**
 * Created by ds on 4/21/16
 */
'use strict';
var App = require('../../app');

App.controller('AlkaCreatePasswordController', function ($scope, $http, $state, $log, authService, AuthExtendedService) {
    $scope.app.name = "Alka";
    $scope.app.domain = "@alka.dk";
    $scope.app.logo = "img/alka/logo.svg";
    $scope.app.settings.headerFixed = true;
    $scope.app.settings.buttonColor = 'btn-black';
    
    $scope.user = {};
    $scope.authError = null;
    $scope.authSuccess = null;
    $scope.credentials = {
        username: '',
        password: ''
    };
    
    $scope.createpassword = function (credentials, domain) {
        var sendCredentials = {
            email: credentials.email,
            password: credentials.password,
        }

        if(domain !== undefined) {
            sendCredentials.email += domain;
        }

        $scope.authError = null;
        $scope.isLoading = true;
        
        // Calling api endpoint to change the 
        $scope.doCreatePassword = function (credentials) {
        	AuthExtendedService.createPassword(credentials).then(function (user) {
                // notificationFactory.success('Welcome back!');
                $scope.isLoading = false;
                $scope.authSuccess = user.data.msg;
            }, function(error) {
                $scope.authError = error.data.msg;
                $scope.isLoading = false;
                //notificationFactory.error('Login failed');
            });
        };

        // Force actual login
        $scope.doCreatePassword(sendCredentials);
    };
    
});