/**
 * Created by nover on 15/01/15.
 */
var App = require('../../app');

App.controller('AuthUserEditController', function($scope, $log, $modal, $window, toaster, AuthUserService, authUser){
    // $log.debug('AuthUserEditController');
    // $log.debug(authUser);

    $scope.authUser = authUser.data;

    $scope.save = function(authUser){
        AuthUserService.updateAuthUser(authUser)
            .then(function(data){
                toaster.pop('success', 'Changes saved', 'Your changes have been successfully saved');
            })
            .catch(function(error){
                // $log.error(error);
                toaster.pop('error', 'Error saving data', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.goBack = function () {
        $window.history.back();
    };
});