/**
 * Created by nover on 15/01/15.
 */
require('./authUserService');
require('./userSettingsService');

require('./app.router.authUser');

require('./authUserListCtrl');
require('./authUserEditCtrl');
require('./userSettingsEditCtrl');
require('./authUserAddModalCtrl');