/**
 * Created by ds on 4/21/16
 */
'use strict';
var App = require('../../app');

App.controller('ToolAnswerFlipController', function ($scope, $log, $modal, $http, $state, authService, ToolService) {
    $scope.flip = {};
    $scope.flip.key = "";

    $scope.flip = function(flip, size){
        var modalInstance = $modal.open({
            templateUrl: 'app.tool.flipanswer.modal.confirmation.html',
            controller: 'ToolAnswerFlipConfirmModalController',
            size: size,
            resolve: {
                flipkey: function () {
                    return flip.key;
                }
            }
        });

        modalInstance.result.then(function (data) {
            //if ($scope.authUsers) {
            //    $scope.authUsers.push(data);
            //
            //}
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
});