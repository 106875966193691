/**
 * Created by nover on 09/01/15.
 */
var App = require('../../app');

App.factory('CompanyService', function ($http, $log) {
    var CompanyService = {};

    /**
     * Get respondents list of a specific company
     * @param id the id of the company
     * @param page the number of page that user in
     * @returns {HttpPromise}
     */
    CompanyService.getRespondentList = function (id, page) {
        return $http.get('/api/company/' + id + '/respondentList/' + page);
    };

    CompanyService.getMyRespondentList = function (page, dep, show) {
        return $http.get('/api/company/respondentList/' + page + '/' + dep + '/' + show);
    }

    /**
     * Search a query over company's respondent list
     * @param id the id of the company
     * @param query the search query
     * @param page the number of page that user in
     * @returns {HttpPromise}
     */
    CompanyService.search = function (id, query, page) {
        return $http.get('/api/company/' + id + '/respondentList/' + page + '/' + query);
    };

    /**
     * Search a query over company's respondent list
     * @param from
     * @param to
     * @returns {HttpPromise}
     */
    CompanyService.export = function (id, from, to) {
        return $http.get('/api/company/' + id + '/export/' + from + '/' + to);
    };

    /**
     * Get a customer location object off it's id
     * @param id The id of the customer
     * @returns {HttpPromise}
     */
    CompanyService.getCompany = function (id) {
        return $http.get('/api/company/' + id);
    };

    /**
     * Save the customer location object
     * @param company Chain location data INCLUDING the customer location id
     * @returns {HttpPromise}
     */
    CompanyService.updateCompany = function(company) {
        return $http.put('/api/company/' + company.id, company);
    };

    /**
     * Save the customer location object
     * @param company Chain location data INCLUDING the customer location id
     * @returns {HttpPromise}
     */
    CompanyService.importCompanyDataLookup = function(company, csv) {
        return $http.put('/api/company/' + company.id + '/data-lookup', csv);
    };

    CompanyService.importAndAppendCompanyDataLookup = function(company, csv) {
        return $http.put('/api/company/' + company.id + '/data-lookup-append', csv);
    };

    /**
     * Save the customer location object
     * @param company Chain location data INCLUDING the customer location id
     * @returns {HttpPromise}
     */
    CompanyService.importCompanyCaseTypes = function(company, csv) {
        return $http.put('/api/company/' + company.id + '/case-types', csv);
    };

    /**
     * Save the customer location object
     * @param company Chain location data INCLUDING the customer location id
     * @returns {HttpPromise}
     */
    CompanyService.importCompanyCaseTypesAndAppend = function(company, csv) {
        return $http.put('/api/company/' + company.id + '/case-types-append', csv);
    };

    /**
     * List all the customers in the system
     * @returns {HttpPromise}
     */
    CompanyService.listCompanies = function (){
        return $http.get('/api/company/');
    };
    return CompanyService;
});