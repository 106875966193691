/**
 * Created by ds on 9/4/15.
 */

var getRespondentList = function ($scope, $log, $modal, promise, ChainDataService, toaster, $window) {
    // $log.info('Respondent List is called');

    $scope.chainData = {};
    $scope.chainData.loading = true;

    var promiseRespondentList = promise.respondentList(1);
    promiseRespondentList.then(function(value) {
        $scope.chainData.loading = false;
        // $log.debug(value.data);
        $scope.chainData.respondentList = value.data.respondentList;
        $scope.chainData.columns = value.data.columns;
        $scope.chainData.pagination = value.data.pagination;
    });

    // Do change page towards respondent list
    $scope.pageChanged = function() {
        if(typeof $scope.chainData.query === "undefined") {
            $scope.chainData.query = "";
        }
        $scope.chainData.loading = true;
        // $log.log('Page changed to: ' + $scope.chainData.pagination.currentPage + ' and query: ' + $scope.chainData.query);

        var promiseRespondentList = promise.search($scope.chainData.query, $scope.chainData.pagination.currentPage);
        promiseRespondentList.then(function(value) {
            $scope.chainData.loading = false;
            // $log.debug(value.data);
            $scope.chainData.respondentList = value.data.respondentList;
            $scope.chainData.pagination = value.data.pagination;
        });
    };

    // Do searching with query towards respondent list
    $scope.search = function() {
        if(typeof $scope.chainData.query === "undefined") {
            $scope.chainData.query = "";
        }
        $scope.chainData.loading = true;
        // $log.log('Search for: ' + $scope.chainData.query);
        $scope.chainData.pagination.currentPage = 1;

        var promiseRespondentList = promise.search($scope.chainData.query, 1);
        promiseRespondentList.then(function(value) {
            $scope.chainData.loading = false;
            // $log.debug(value.data);
            $scope.chainData.respondentList = value.data.respondentList;
            $scope.chainData.pagination = value.data.pagination;
        });
    };

    $scope.export = function(id, from, to) {
        if(typeof from === "undefined") {
            alert("Please define when the export is started!");
            return;
        }
        if(typeof to === "undefined") {
            alert("Please define when the export is ended!");
            return;
        }

        $window.open('api/company/' + id + '/export/' + from + '/' + to);
    }

    // Resend invitation for one particular respondent
    $scope.forceSendInvitation = function (id) {
        var forceSendInvitation = ChainDataService.forceSendInvitation(id);
       //  $log.log("Sending email to " + id);
        forceSendInvitation.then(function (data) {
                toaster.pop('success', 'Invitation is sent!', 'Your invitation is sent');
            })
            .catch(function (error) {
                toaster.pop('error', 'Error on sending invitation', 'Unable to send invitation: ' + error.data.msg + ' (HTTP: ' + error.status + ')');
            })
    }

    // Edit specific respondent
    $scope.openEditRespondentDialog = function (respondent, size) {
        var modalInstance = $modal.open({
            templateUrl: 'app.chainData.modal.editrespondent.html',
            controller: 'RespondentEditModalController',
            resolve: {
                respondent: function () {
                    return respondent;
                },
                promise: function () {
                    return promise;
                }
            },
            size: size
        });

        modalInstance.result.then(function (data) {
           //  $log.debug(data, "Newly submitted data");
            $scope.chainData.respondentList.forEach(function(entry) {
                if(entry['id'] == data['id']) {
                    entry['1'] = data['1'];
                    entry['2'] = data['2'];
                    entry['5'] = data['5'];
                    entry['6'] = data['6'];
                }
            });
            $scope.chainData.respondentList = $scope.chainData.respondentList;
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };

};

module.exports.getRespondentList = getRespondentList;