/**
 * Created by nover on 09/01/15.
 */
var chainDataCtrl = require('./chainDataCtrl');
var App = require('../../app');

App.controller('CompanyEditController', function ($scope, $log, $window, $modal, CompanyService, ChainDataService,
                                                  toaster, company, promise, Session) {
    // $log.debug(company, 'Company edit controller - data... +');

    $scope.showDataLookupBulkImport = false;
    $scope.dataLookupButtons = true;

    $scope.showCaseTypesBulkImport = false;
    $scope.caseTypesButtons = true;

    $scope.userInfo = Session.user;

    var role = $scope.userInfo.role;

    if(role.indexOf("admin") === 0) {
        $scope.role = "admin";
    }
    else if(role.indexOf("admin") !== -1) {
        $scope.role = "member_admin";
    }
    else {
        $scope.role = "user";
    }

    $scope.company = company.data;

    $scope.exportDataLookupMethods = [
        {
            id: 0,
            name: 'Case type'
        },
        {
            id: 1,
            name: 'Lawyer initials'
        }
    ];
    $scope.exportDataLookupMethods.selected = $scope.exportDataLookupMethods[$scope.company.exportDataLookupMethod];

    $scope.addDataLookupEntry = function () {
        $scope.company.exportDataLookupTable.push({
            input: '',
            output: ''
        });
    };

    $scope.removeDataLookupEntry = function (index) {
        $scope.company.exportDataLookupTable.splice(index, 1);
    };


    $scope.addVolumeCustomer = function () {
        $scope.company.volumeCustomers.push({
            email: 'my@email.dk',
            delay: 30
        });
    };

    $scope.removeVolumeCustomer = function (index) {
        $scope.company.volumeCustomers.splice(index, 1);
    };


    $scope.addCaseType = function () {
        $scope.company.caseTypes.push({});
    };
    $scope.removeCaseType = function (index) {
        $scope.company.caseTypes.splice(index, 1);
    };

    $scope.addBlacklistedEmail = function () {
        $scope.company.blacklist.push({
            email: 'his@email.dk',
            comment: 'hates email'
        });
    };

    $scope.removeBlacklistedEmail = function (index) {
        $scope.company.blacklist.splice(index, 1);
    };

    $scope.goBack = function () {
        $window.history.back();
    };

    $scope.save = function (company) {
        CompanyService.updateCompany(company)
            .then(function (data) {
                toaster.pop('success', 'Changes saved', 'Your changes were saved');
            })
            .catch(function (error) {
                toaster.pop('error', 'Error saving', 'Unable to save: ' + error.data.msg + ' (HTTP: ' + error.status + ')');
            })
    }

    $scope.addValidationRule = function() {
        $scope.company.validationRules.push({
            target: '',
            rule: ''
        });
    };

    $scope.removeValidationRule = function(index) {
        $scope.company.validationRules.splice(index, 1);
    };

    // DataLookup Bulk Import
    $scope.showDataLookupBulkUploadForm = function() {
        $scope.showDataLookupBulkImport = true;
        $scope.dataLookupButtons = false;
    }
    $scope.cancelDataLookupBulkUpload = function() {
        $scope.showDataLookupBulkImport = false;
        $scope.dataLookupButtons = true;
    }
    $scope.dataLookupBulkImport = function(company) {
        CompanyService.importCompanyDataLookup(company, document.getElementById('dataToImport').value)
            .then(function (data) {
                toaster.pop('success', 'Changes saved', 'Your changes were saved');
                document.getElementById('dataToImport').value = '';
                $scope.cancelDataLookupBulkUpload();
                $scope.reloadCompany(company)
            })
            .catch(function (error) {
                toaster.pop('error', 'Error saving', 'Unable to save: ' + error.data.msg + ' (HTTP: ' + error.status + ')');
            })

    }

    $scope.dataLookupBulkImportAndAppend = function(company) {
        CompanyService.importAndAppendCompanyDataLookup(company, document.getElementById('dataToImport').value)
            .then(function (data) {
                toaster.pop('success', 'Changes saved', 'Your changes were saved');
                document.getElementById('dataToImport').value = '';
                $scope.cancelDataLookupBulkUpload();
                $scope.reloadCompany(company)
            })
            .catch(function (error) {
                toaster.pop('error', 'Error saving', 'Unable to save: ' + error.data.msg + ' (HTTP: ' + error.status + ')');
            })

    }

    // Case Types Bulk Import
    $scope.showCaseTypesBulkUploadForm = function() {
        $scope.showCaseTypesBulkImport = true;
        $scope.caseTypesButtons = false;
    }
    $scope.cancelCaseTypesBulkUpload = function() {
        $scope.showCaseTypesBulkImport = false;
        $scope.caseTypesButtons = true;
    }
    $scope.caseTypesBulkImport = function(company) {

        CompanyService.importCompanyCaseTypes(company, document.getElementById('caseTypesDataToImport').value)
            .then(function (data) {
                toaster.pop('success', 'Changes saved', 'Your changes were saved');
                document.getElementById('caseTypesDataToImport').value = '';
                $scope.cancelCaseTypesBulkUpload();
                $scope.reloadCompany(company)
            })
            .catch(function (error) {
                toaster.pop('error', 'Error saving', 'Unable to save: ' + error.data.msg + ' (HTTP: ' + error.status + ')');
            })
    }
    $scope.caseTypesBulkImportAndAppend = function(company) {

        CompanyService.importCompanyCaseTypesAndAppend(company, document.getElementById('caseTypesDataToImport').value)
            .then(function (data) {
                toaster.pop('success', 'Changes saved', 'Your changes were saved');
                document.getElementById('caseTypesDataToImport').value = '';
                $scope.cancelCaseTypesBulkUpload();
                $scope.reloadCompany(company)
            })
            .catch(function (error) {
                toaster.pop('error', 'Error saving', 'Unable to save: ' + error.data.msg + ' (HTTP: ' + error.status + ')');
            })
    }

    $scope.reloadCompany = function(company) {
        CompanyService.getCompany(company.id)
            .then(function (data) {
                $scope.company = data.data;
                toaster.pop('success', 'Reloaded', 'Company data is now saved and reload');
            })
            .catch(function (error) {
                toaster.pop('error', 'Error saving', 'Unable to save: ' + error.data.msg + ' (HTTP: ' + error.status + ')');
            })

    }

    chainDataCtrl.getRespondentList($scope, $log, $modal, promise, ChainDataService, toaster, $window);
});
