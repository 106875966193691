'use strict';
var app = require('../../app');
/* Controllers */
// signin controller
app.controller('ResetPwdFormController', ['$scope', '$http', '$state', '$location', 'authService', 'AuthExtendedService', function ($scope, $http, $state, $location, authService, AuthExtendedService) {
    $scope.credentials = {
        code: $scope.code,
        password: '',
        repassword: ''
    };
    $scope.process = {
        isLoading: false,
        isSuccess: false,
        isError: false
    }

    $scope.rmLogin = function (app) {
        alert(app);
    }

    $scope.errorMessage = "";

    $scope.resetPwd = function (credentials) {
        $scope.process.isSuccess = false;
        $scope.process.isError = false;

        $scope.isCollapsed = !$scope.isCollapsed;
        // Try reset password
        $scope.resetPwdAct = function (credentials) {
            var apiReq = AuthExtendedService.resetPwd(credentials);
            apiReq.then(function (data) {
                $scope.process.isLoading = false;
                $scope.process.isSuccess = true;

            }, function (error) {
                $scope.process.isLoading = false;
                $scope.process.isError = true;
                $scope.errorMessage = error.data.msg;
            });
        };

        // Force actual reset password
        $scope.process.isLoading = true;
        $scope.resetPwdAct(credentials);
    }
}]);
