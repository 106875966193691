/**
 * Created by nover on 08/01/15.
 */
var App = require('../../app');

App.factory('ChainService', function ($http, $log) {
    var ChainService = {};

    /**
     * Get respondents list of a specific chain
     * @param id the id of the company
     * @param page the number of page that user in
     * @returns {HttpPromise}
     */
    ChainService.getRespondentList = function (id, page) {
        return $http.get('/api/chain/' + id + '/respondentList/' + page);
    };

    /**
     * Search a query over company's respondent list
     * @param id the id of the company
     * @param query the search query
     * @param page the number of page that user in
     * @returns {HttpPromise}
     */
    ChainService.search = function (id, query, page) {
        return $http.get('/api/chain/' + id + '/respondentList/' + page + '/' + query);
    };

    /**
     * Lists all the customers in the system
     * @returns {HttpPromise}
     */
    ChainService.listChains = function () {
        return $http.get('/api/chain');
    };

    /**
     * Creates a new chain in the system
     * @param chain The chain data
     * @returns {HttpPromise}
     */
    ChainService.createChain = function (chain) {
        return $http.post('/api/chain', chain);
    };

    /**
     * Creates a new chain user
     * @param chainId The id of the chain that should own the usr
     * @param user The user data
     * @returns {HttpPromise}
     */
    ChainService.createChainUser = function (chainId, user) {
        return $http.post('/api/chain/' + chainId + '/users', user);
    };

    /**
     * Creates a new chain location
     * @param chainId The id of the chain that should own the location
     * @param location The location data
     * @returns {HttpPromise}
     */
    ChainService.createCompany = function (chainId, location) {
        return $http.post('/api/chain/' + chainId + '/locations', location);
    };

    /**
     * Get a chain object off it's id
     * @param id The id of the chain
     * @returns {HttpPromise}
     */
    ChainService.getChain = function (id) {
        return $http.get('/api/chain/' + id);
    };

    /**
     * Returns all the references pointing to a chain in the system (users, locations, etc)
     * @param id The id of the chain to fetch references for.
     * @returns {HttpPromise}
     */
    ChainService.getChainReferences = function (id) {
        return $http.get('/api/chain/' + id + '/references');
    };

    /**
     * Update details on a chain
     * @param chain The chain data object
     * @returns {HttpPromise}
     */
    ChainService.updateChain = function(chain) {
        return $http.put('/api/chain/' + chain.id, chain);
    };
    return ChainService;
});