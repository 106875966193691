var App = require('../../app');

App.factory('ToolAlkaService', function ($http, $log) {
    var ToolAlkaService = {};

    /**
     * Flip the answer with given key
     * @param flipkey
     * @returns {*}
     */
    ToolAlkaService.moveDataToOtherAgent = function (data) {
        return $http.post('/api/tools/alka/movechaindatatootheragent', data);
    };

    return ToolAlkaService;
});