/**
 * Created by nover on 08/01/15
 */
var App = require('../../app');

App.controller('CompanyUserAddModalController', function ($scope, $log, $modalInstance, CompanyUserService, toaster, surveys) {
    //$log.debug('add company customer modal controller exec');

    $scope.surveys = [];
    for (index = 0; index < surveys.length; ++index) {
        $scope.surveys.push({
            "id":surveys[index].id,
            "name":surveys[index].name
        });
    }

    $scope.onSurveySelected = function (id) {
        //$log.debug('survey selected');

        var survey = null;
        for (index = 0; index < surveys.length; ++index) {
            var s = surveys[index];
            if(s.id == id) {
                survey = s;
                break;
            }
        }

        $scope.companies = [];

        var surveyCompanies = survey.companies;

        for (index = 0; index < surveyCompanies.length; ++index) {
            $scope.companies.push({
                "id":surveyCompanies[index].id,
                "name":surveyCompanies[index].name
            });
        }
    }

    $scope.ok = function (authUser) {
        $log.debug(authUser);

        authUser.surveyId = authUser.survey.id;
        authUser.companyId = authUser.company.id;

        CompanyUserService.createNewCompanyUser(authUser)
            .then(function (data) {
                $log.debug(data);
                $modalInstance.close(data.data);

            }).catch(function (error) {
                $log.error(error);
                toaster.pop('error', 'Unable to save', error.data.msg + ' (HTTP: ' + error.status + ')');
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
});
