/**
 * Created by nover on 22/12/14
 */
var App = require('../../app');

App.factory('AuthUserService', function($http, $log){
    var AuthUserService = {};

    /**
     * List all auth users in the system
     * @returns {HttpPromise}
     */
    AuthUserService.listAuthUsers = function(){
        return $http.get('/api/authuser');
    };

    /**
     * Add a new auth user to the system
     * @param authUser
     * @returns {HttpPromise}
     */
    AuthUserService.createNewAuthUser = function(authUser){
        return $http.post('/api/authuser', authUser);
    };

    /**
     * Get the given auth user from the system
     * @param id The id of the auth user
     * @returns {HttpPromise}
     */
    AuthUserService.getAuthUser = function(id) {
        return $http.get('/api/authuser/' + id);
    };

    /**
     * Update auth user
     * @param authUser The auth user data object including the unique id
     * @returns {HttpPromise}
     */
    AuthUserService.updateAuthUser = function(authUser) {
        return $http.put('/api/authuser/' + authUser.id, authUser);
    };

    return AuthUserService;
});