/**
 * Created by nover on 19/02/15.
 */

var App = require('../../app');
App
    .config(
    ['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.survey-list', {
                    url: '/survey/list',
                    templateUrl: 'templates/app.survey.list.html',
                    controller: 'SurveyListController',
                    ncyBreadcrumb: {
                        label: 'survey list'
                    },
                    resolve: {
                        surveyList: function (SurveyService) {
                            return SurveyService.list();
                        }
                    }
                })
                .state('app.survey-edit', {
                    url: '/survey/edit/:id',
                    templateUrl: 'templates/app.survey.edit.html',
                    controller: 'SurveyEditController',
                    ncyBreadcrumb: {
                        label: 'Survey editor',
                        parent: 'app.survey-list'
                    },
                    resolve: {
                        survey: function ($stateParams, SurveyService) {
                            return SurveyService.get($stateParams.id);
                        }
                    }
                })
        }
    ]);