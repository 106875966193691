/**
 * Created by nover on 15/01/15.
 */
var App = require('../../app');

App.controller('AuthUserListController', function($scope, $log, $modal, AuthUserService){
    // $log.debug('AuthUserListController');

    $scope.busy = true;

    //Initial request
    var apiReq = AuthUserService.listAuthUsers();
    apiReq.then(function (value) {
        delete value.data.error;
        delete value.data.status;
        $scope.authUsers = value.data.users;
        $scope.busy = false;
    });

    $scope.openAddAuthUserDialog = function(size){
        var modalInstance = $modal.open({
            templateUrl: 'app.authUser.modal.addAuthUser.html',
            controller: 'AuthUserAddModalController',
            size: size
        });

        modalInstance.result.then(function (data) {
            if ($scope.authUsers) {
                $scope.authUsers.push(data);

            }
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };

});