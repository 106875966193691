/**
 * Created by nover on 17/12/14.
 */
// take from gist: https://gist.github.com/jeffjohnson9046/9470800
var App = require('../../app');

// This filter makes the assumption that the input will be in decimal form (i.e. 17% is 0.17).
App.filter('percentage', ['$filter', '$log', function ($filter, $log) {
    return function (input, decimals) {
        if(!input){
            // $log.warn('Percentage filter received undefined input, defaulting value');
            return '0%';
        }

        return $filter('number')(input * 100, decimals) + '%';
    };
}]);