/**
 * Created by nover on 22/12/14.
 */
var App = require('../../app');

App.factory('ProjectService', function ($http, $log) {
    var ProjectService = {};

    ProjectService.listProjects = function () {
        return $http.get('/api/project');
    };

    ProjectService.createNewProject = function (project) {
        return $http.post('/api/project', project);
    };

    ProjectService.getProject = function (id) {
        return $http.get('/api/project/' + id);
    };

    return ProjectService;
});