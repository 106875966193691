/**
 * Created by nover on 22/12/14.
 */
var App = require('../../app');

App.controller('ProjectEditController', function($scope, $log, project, authUsers){
    $log.debug('In project edit controller');
    $scope.project = project.data;

    $scope.authUsers = authUsers.data.users;
});