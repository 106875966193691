/**
 * Created by nover on 16/12/14.
 */
'use strict';
var App = require('../../app');

App.controller('DashboardLogDataCollectionController', function ($scope, $log, $stateParams, DashboardService, deps) {
    // $log.debug('In dashboard log data collection controller');
    $scope.data = deps.data;

    $scope.pageChanged = function() {
        $scope.data.loading = true;
        // $log.log('Page changed to: ' + $scope.data.pagination.currentPage);

        var promiseRespondentList = DashboardService.getLogDataCollection($scope.data.pagination.currentPage);
        promiseRespondentList.then(function(value) {
            $scope.data.loading = false;
            // $log.debug(value.data);
            $scope.data = value.data;
        });
    };

});