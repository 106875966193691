/**
 * Created by ds on 9/4/15.
 */
var App = require('../../app');

App.factory('ChainDataService', function ($http, $log) {
    var ChainDataService = {};

    ChainDataService.forceSendInvitation = function (id) {
        return $http.post('/api/chainData/' + id + '/sendInvitation');
    };

    ChainDataService.updateRespondent = function(id, respondent) {
        return $http.put('/api/chainData/' + id, respondent);
    };

    ChainDataService.getRespondent = function(id) {
        return $http.get('/api/chainData/' + id);
    };

    ChainDataService.selectResponse = function(id) {
        return $http.put('/api/selectResponse/' + id);
    }

    return ChainDataService;
});