'use strict';
var App = require('../../app');

App.controller('ToolMoveChainDataToOtherAgentController', function ($scope, $log, $http, $state, toaster, authService, ToolAlkaService) {
		
	$scope.data = {};
	$scope.data.rmkey = "";
	$scope.data.chainUsername = "";
	
    $scope.moveData = function(data){
    	ToolAlkaService.moveDataToOtherAgent(data)
        .then(function(response){
            toaster.pop('success', 'Move Data', response.data.msg);
        })
        .catch(function(error){
            $log.error(error);
            toaster.pop('error', 'Error saving data', error.data.msg + ' (HTTP: ' + error.status + ')');
        });
    };
	
});