/**
 * Created by nover on 16/12/14.
 */
'use strict';
/**
 * Authentication - user roles
 */
var App = require('../../app');

angular.module('app').constant('USER_ROLES', {
    admin: 'admin',
    member: 'member'
});

